import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';
const apiUrl = environment.serverUrl + 'api/';
@Injectable({
  providedIn: 'root'
})

export class EquipeService {


getAgents() {
    let subUrl =this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/users/asvp';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
}

requestPosition(userId): Observable<any> {
    let subUrl = this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/users/requestPosition?userId='+ userId;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
}

constructor(private http: HttpClient, private authenSer: AuthenticationService) { }
}
