import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { DisplayService } from '../display.service';

@Component({
  selector: 'app-display-msg',
  templateUrl: './display-msg.component.html',
  styleUrls: ['./display-msg.component.scss']
})
export class DisplayMsgComponent  implements OnInit {
  JSON: JSON;
  modeMessage = new Array();
  modeMessageSelected ;
  keys = Object.keys;

  listVars: Array<any>;
  DisplayColors = [{name: 'YELLOW', label: 'Jaune'}, {name:'RED', label: 'Rouge'},
   {name: 'GREEN', label: 'Vert'}, {name: 'BLUE', label: 'Bleu'}, {name: 'WHITE', label: 'Blanc'}];
   DisplayModes = [{label: "Normal" ,name: "NORMAL"},
   {label: "Gras" ,name: "BOLD"},{label: "Défilement" ,name: "SCROLLING"},
   {label: "Alternance" ,name: "ALTERNATE"},{label: "Clignotant" ,name: "BLINK"}];
  display: any;

  dataAbsenceForm = this.fb.group({
    messages: this.fb.array([]),
      mode: [],
      color: [],
      messageUniique: [],
  });
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    protected modalService: NgbModal,
    private displayServ: DisplayService,
    private alertServ: AlertService,
    private authenSer: AuthenticationService,
    private formService: FormChangeService,
  ) {

  }


isValid(f){
  return f.valid;
}
deleteDataAbsence(index) {
  this.messages.controls = this.messages.controls.filter((el,elIndex) => {return elIndex != index});
  this.messages.updateValueAndValidity();
}
  addDataAbsence() {
    this.messages.push(
      this.fb.group({
        msg: new FormControl("", [Validators.required]),
      })
    );

  }
  ngOnInit() {
    this.updateForm();
    }

 public updateForm() {
  debugger;
      let modeDisplay = this.display.mode;
    // if (messagesData) {
      if(modeDisplay == 'ALTERNATE') {
        if (this.display.msg && this.display.msg.length > 0) {
          this.display.msg.forEach(element => {
          this.messages.push(
              this.fb.group({
                msg: new FormControl(element)})
            )
          });
        } else {
          this.messages.push(
            this.fb.group({
              msg: new FormControl('')})
          )
        }
      } else {
        this.dataAbsenceForm.patchValue({
          messageUniique: [this.display.msg[0]]
        });
      }

      // let modeDisplay = this.display.mode?this.display.mode: 'NORMAL';
      // let color = this.display.color?this.display.color: 'GREEN';
      this.dataAbsenceForm.patchValue({
        mode: modeDisplay,
        color: this.display.color,
      });
}


  update() {
    debugger;
    let messagesData = this.dataAbsenceForm.value.messages as Array<any>;
    let messages = new Array<any>();
    let modeDisplay = this.dataAbsenceForm.get(["mode"]).value;
    if(modeDisplay == 'ALTERNATE') {
      if (messagesData) {
        messagesData.forEach((newDataAbsence) => {
          messages.push(newDataAbsence.msg);
        });
      } else {
        messages.push( this.dataAbsenceForm.value.messageUniique);
      }
    }

    let color = this.dataAbsenceForm.get(["color"]).value;
    this.displayServ
      .pushDisplayMsg( this.display.id, modeDisplay, color, messages)
      .subscribe(
        (data) => {
          this.alertServ.success("Message Sauvegardé !", true);
          this.formService.raisereloadSearchRequestDisplayEvent();
          this.close();
        },
        (error) => {
          console.log(error);
          this.alertServ.error("Erreur dans le formulaire !", true);
          this.close();
        }
      );
  }
  close() {
    this.activeModal.close();
  }
  get messages() {
    return this.dataAbsenceForm.get('messages') as FormArray
  }
  get modeAffichage() {
    return this.dataAbsenceForm.get('mode')!.value;
  }
  modeChange(e) {
    debugger;
    if(this.modeAffichage != 'ALTERNATE') {
      this.dataAbsenceForm.patchValue({
        messages: this.fb.array([
          this.fb.group({
            msg: new FormControl("", [])
          })
        ])})
      } else {
        this.dataAbsenceForm.patchValue({
          messages: this.fb.array([
            this.fb.group({
              msg: new FormControl("", [])
            })
          ])})
      }
        this.addDataAbsence();
}

}
