import { Component, Input, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/customer/customer.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { UserService } from 'src/app/user/user.service';
import { DisplayService } from '../display.service';

@Component({
  selector: 'app-form-min-displays',
  templateUrl: './form-min-displays.component.html',
  styleUrls: ['./form-min-displays.component.scss']
})
export class FormMinDisplaysComponent implements OnInit {
  searchText: string;
  model: NgModel;
  @Input() withPeriod: boolean;
  period: any;
  selectedPeriod: string;
  periodList: any[];
  constructor(
    private alertService: AlertService,
    private customerService: CustomerService,
    private authenSer: AuthenticationService,
    private formService: FormChangeService,
    public displayService: DisplayService,
    public router: Router) {
  }


  ngOnInit() {
    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadDisplaySearchRequest.subscribe(
      () => {

        this.rechercher();
      });
  }
  clearForm() {
    this.rechercher();
  }
  private filterUsersByText(users) {
    let filter = this.getTextFilter();
    if (filter) {
      let text = filter.split(' ');

      return users.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = item['label'].toUpperCase().indexOf(el.toUpperCase()) > -1
            || item['city'].toUpperCase().indexOf(el.toUpperCase()) > -1
            || item['adress'].toUpperCase().indexOf(el.toUpperCase()) > -1;
            return tmp;
        });
        return flt;
      });
    } else {
      return users;
    }
  }

  private getTextFilter() {
    return this.searchText;
  }

rechercher() {
    this.getDisplayFromBackend()
      .subscribe(
        data => {
          let tmpUsers = data as Array<any>;
          tmpUsers = this.filterUsersByText(tmpUsers);
          debugger;
          this.formService.raiseFormMinDisplaySearch(tmpUsers);
        }
        , error => {

          console.log(error);
          this.formService.raiseFormMinDisplaySearch([]);

        });
  }


  getDisplayFromBackend() {
    return this.displayService.getDisplays();
  }

  onFormChange() {
    this.rechercher();
  }

}
