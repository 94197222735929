import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { AlertService } from '../../services/alert-service.service';
import { FormChangeService } from '../../services/form-change.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CustomerService } from '../../customer/customer.service';
import { TenantService } from '../../services/tenant.service';
import { Role } from 'src/app/class/role';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  userCreateForm: FormGroup;
  submitted = false;
  searchText;
  customers: {};
  customerId;
  tenantId;
  selectedCivilite: string = null;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  userForm: any;
  selectedCustomer;
  roles = new Array();
  selectedRoles = new Array();
  selectedTenant: any[];
  tenants: Object;
  constructor(protected customerService: CustomerService,
    protected userService: UserService,
    private fb: FormBuilder,
    private tenantServ: TenantService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) {

      Object.keys(Role).forEach(rl =>{
        this.roles.push(Role[rl]);
      });

     }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.userCreateForm = this.fb.group({
      customerId: [],
      tenantId: [],
      firstname: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
    if (!this.authenSer.isAdmin){
      this.userCreateForm.addControl(
        'login', new FormControl("", [Validators.required])
        );
    }
    this.rechercher();
  }
  get f() { return this.userCreateForm.controls; }



  private createFromForm() {
    let tmp = {
      customerId: this.userCreateForm.get(['customerId']).value,
      tenantId: this.authenSer.isSupperAdmin?this.userCreateForm.get(['tenantId']).value:null,
      email: this.userCreateForm.get(['email'])!.value,
      firstname: this.userCreateForm.get(['firstname'])!.value,
      name: this.userCreateForm.get(['name'])!.value,
      roles: this.selectedRoles,
    };
    if (!this.authenSer.isAdmin) {
      tmp['login'] = this.userCreateForm.get(["login"])!.value;
    }
    return tmp;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.userCreateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();

      this.subscribeToSaveResponse(this.userService.saveUser(this.userForm));
    }
  }

  rechercher() {
    let filter = this.getFilter();
    this.getCustomersFromBackEnd(filter)
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.customers = data;
          this.selectedCustomer = tmp;
        }
        , error => {
          console.log(error);
          this.formService.raiseCustomerListEvent({});
        });

    if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
        }
        , error => {
          console.log(error);
        });
    }
  }

  getCustomersFromBackEnd(filter) {
    return this.customerService.getCustomers();
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Utilisateur sauvegardé !', true);
    this.formService.raisereloadSearchRequestUserEvent();
    this.close();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', true);
  }
  private getFilter() {

    let filter = this.searchText ? this.searchText : null;
    return filter;
  }
}
