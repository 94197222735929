import { Component, Input, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/customer/customer.service';
import { DisplayService } from 'src/app/displays/display.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ZonesService } from '../zones.service';

@Component({
  selector: 'app-form-min-zone',
  templateUrl: './form-min-zone.component.html',
  styleUrls: ['./form-min-zone.component.scss']
})
export class FormMinZoneComponent implements OnInit {
  searchText: string;
  model: NgModel;
  constructor(
    private alertService: AlertService,
    private customerService: CustomerService,
    private authenSer: AuthenticationService,
    private formService: FormChangeService,
    public zoneServ: ZonesService,
    public router: Router) {
  }


  ngOnInit() {

    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadZoneSearchRequest.subscribe(
      () => {
        this.rechercher();
      });
  }
  clearForm() {
    this.rechercher();
  }
  private filterUsersByText(users) {
    let filter = this.getTextFilter();
    if (filter) {
      let text = filter.split(' ');
      return users.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = item['label'].toUpperCase().indexOf(el.toUpperCase()) > -1
            return tmp;
        });
        return flt;
      });
    } else {
      return users;
    }
  }

  private getTextFilter() {
    return this.searchText;
  }

rechercher() {
    this.getZoneFromBackend()
      .subscribe(
        data => {

          let tmpUsers = data as Array<any>;
          tmpUsers = this.filterUsersByText(tmpUsers);
          this.formService.raiseFormMinZoneSearch(tmpUsers);
        }
        , error => {
          console.log(error);
          this.formService.raiseFormMinZoneSearch([]);

        });
  }


  getZoneFromBackend() {
    return this.zoneServ.getZones();
  }

  onFormChange() {
    this.rechercher();
  }

}
