import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';
const apiUrl = environment.serverUrl + 'api/';
@Injectable({
  providedIn: 'root'
})
export class StatsService {

  constructor(private http: HttpClient, private authenServ: AuthenticationService) { }

  getStats(fromPeriod: any){

    let subUrl = this.authenServ.getUserApiTargetUrl();
    const url = apiUrl + subUrl + '/statistics/stats'+fromPeriod;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
  }
  getAdminStats(data: any){
    let subUrl = this.authenServ.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/statistics/stats?target=' + data.target + '&timeFrom='
    + data.timeFrom + '&timeTo=' + data.timeTo + '&unitTime=' + data.unitTime;

    if(data.targetIds){
      let subUrl ='';
      data.targetIds.forEach(element => {
        url += '&targetIds=' + element ;
      });
    }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
  }
}
