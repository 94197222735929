import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from 'src/app/services/form-change.service';
import { Router } from '@angular/router';
import { MomentPipe } from 'src/app/moment-pipe.pipe';
import { NotificationsService } from '../notifications.service';
import { ParkingService } from 'src/app/services/parking.service';
import { ZonesService } from 'src/app/zones/zones.service';

@Component({
  selector: 'app-notification-update',
  templateUrl: './notification-update.component.html',
  styleUrls: ['./notification-update.component.scss']
})
export class NotificationUpdateComponent implements OnInit, OnDestroy {
  timerKeepAlive:any;
  employeesCreateForm: FormGroup;
  keys = Object.keys;
  degicods;
  places;
  zones;
  selectedCustomer: string = null;
  selectedCivilite: string = null;
  notificationToSave: any;
  emailInvalid: boolean;
  firstContact;
  lastContact;
  typeContrat = [
    { id: 1, code: "TEL", name: "Tel" },
    { id: 2, code: "MAIL", name: "Email" },
    {id:3, code: "SMS", name: "SMS"},
    {id: 4, code: "PUSH", name: "Push"},
  ];
  typeEvent = [
    { id: 1, code: "BUSY", name: "place occupée" },
    { id: 2, code: "FREE", name: "place libérée" },
    {id: 3, code: "OUT_OF_TIME", name: "dépassement"},
  ];
  configurationTypes = [
    {id: 1, name: 'PMR', label: 'PMR'},
    {id: 2, name: 'Paying', label: 'Payante'},
    {id: 3, name: 'Free', label: 'Gratuite'},
    {id: 4, name: 'Resident',label: 'Résident'},
    {id: 5, name: 'Private', label: 'Privée'},
    {id: 6, name: 'Rescue', label: 'Pompier'},
    {id: 7, name: 'Delivering', label: 'Livraison'}
  ];
  notification : any;
  constructor(
    private fb: FormBuilder,
    protected alertService: AlertService,
    private digicodService: ParkingService,
    private zoneServ: ZonesService,
    private notificationServ: NotificationsService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private formService: FormChangeService,
    private route: Router,
    private momentPipe: MomentPipe
  ) {
    this.notification = this.route.getCurrentNavigation().extras.state?this.route.getCurrentNavigation().extras.state.notification:null;
  }
  ngOnDestroy(): void {
    clearInterval(this.timerKeepAlive);
  }
  refreshDestinataire(){
    this.employeesCreateForm.patchValue(
      {
        destValue: "",
      }
    );
  }
  refreshTarget(){
    this.employeesCreateForm.patchValue(
      {
        targetIds: "",
      }
    );
  }
  ngOnInit(): void {

    this.digicodService.getMyPlacesById().subscribe(
      data => {
      this.places = data;
      this.places.forEach(el => {
        el.label = el.street + ', Place N° : '+ el.label;
      });
      this.zoneServ.getZones().subscribe(
        (data) =>{
          this.zones = data;
        }
      );
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);

    });
    this.initForm();
    if(this.notification){
      this.updateForm(this.notification);
    }
  }

  updateForm(variable: any): void {
    this.employeesCreateForm.patchValue({
      id : variable.id,
      accessCodeReference: variable.accessCodeReference,
      destValue: variable.destValue,
      targetIds: variable.targetIds,
      target: variable.target,
      eventType: variable.eventType,
      nbExecution: variable.nbExecution,
      nbNextOccurence: variable.nbNextOccurence,
      type: variable.type,
      userId: variable.userId,
    });

  }
  initForm() {
    this.employeesCreateForm = this.fb.group({
      destValue: new FormControl(""),
      targetIds: [],
      target: new FormControl("", Validators.required),
      eventType: new FormControl(""),
      id: new FormControl(""),
      nbExecution: new FormControl(""),
      waitBeforeSendMin: new FormControl(""),
      type: new FormControl(""),
    });

  }

  private createFromForm(): any {
    let tmp = {
      id: this.notification.id,
      destValue: this.employeesCreateForm.get(["destValue"]).value?this.employeesCreateForm.get(["destValue"]).value:null,
      targetIds: this.employeesCreateForm.get(["targetIds"]).value? this.employeesCreateForm.get(["targetIds"]).value: null,
      target: this.employeesCreateForm.get(["target"]).value? this.employeesCreateForm.get(["target"]).value: null,
      eventType: this.employeesCreateForm.get(["eventType"]).value?this.employeesCreateForm.get(["eventType"]).value:null,
      waitBeforeSendMin: this.employeesCreateForm.get(["waitBeforeSendMin"])!.value,
      nbExecution: 1,
      type: this.employeesCreateForm.get(["type"])!.value,
    };

    return tmp;
  }
  save(): void {
    this.notificationToSave = this.createFromForm();

    this.notificationServ
      .putNotification(this.notificationToSave)
      .subscribe(
        (data) => {
          this.alertService.success("Notification Sauvegardé !", true);
          this.formService.raiseReloadNotificationsSearchRequestEvent();
          this.route.navigateByUrl("/events");
        },
        (error) => {
          this.alertService.error("Erreur dans le formulaire !", true);
        }
      );
  }
}
