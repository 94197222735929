import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';
const apiUrl = environment.serverUrl + 'api/';
@Injectable({
  providedIn: 'root'
})
export class DisplayService {
  deleteDisplays(id: any) {
    let subUrl = this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/displays/' + id;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(url);

}

saveDisplays(display): Observable<any> {
  let subUrl = this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/displays';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(url, display);
}
pushDisplayMsg(displayId: string, mode: string, color: string,  messages: Array<string>){
  let subUrl = this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/displays/pushMsg?displayId='+displayId+'&mode='+mode + '&color='+ color;
    messages.forEach(el => {
      url += '&msg='+el;
    });
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(url, messages);
}
getDisplays() {
    let subUrl =this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/displays';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
}

putDisplays(display): Observable<any> {
    let subUrl = this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/displays';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(url, display);
}

enableDisplay(enable, idDisplay){
  let subUrl = this.authenSer.getUserApiTargetUrl();
  let url = apiUrl + subUrl + '/displays';
  if(enable){
    url += '/enable';
  } else {
    url += '/disable';
  }
  url += '?displayId='+ idDisplay;
  let headers = new HttpHeaders();
  headers = headers.append('Content-Type', 'application/json');
  return this.http.post(url, null);
}
constructor(private http: HttpClient, private authenSer: AuthenticationService) { }
}
