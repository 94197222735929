import * as moment from 'moment';
export class TimeFormatter {
    static format(value) {
        if (value) {

          let d = new Date(value);
          moment.locale('fr');
          return moment(d).format("DD/MM/YYYY HH:mm")
        }
        else {
          ''
        };
      }
}
