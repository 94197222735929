import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AlertService } from './alert-service.service';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Role } from 'src/app/class/role';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    constructor(private http: HttpClient, private router: Router,
      private alertService: AlertService) { }
  get isAdmin() {
    let user = this.getCurrentUser();
    return user && (user.roles.includes(Role.Admin.toString()));
  }
get UserHasAdminPrevilege(){
  return this.isAdmin || this.isSupperAdmin;
}
  get isMarchant() {
    let user = this.getCurrentUser();

    return user && (user.roles.includes(Role.Marchant.toString()));
  }
  get isAgent() {
    let user = this.getCurrentUser();
    return user && (user.roles.includes(Role.Agent.toString()));
  }
  get isUser() {
    let user = this.getCurrentUser();

    return user && (user.roles.includes(Role.User.toString()));
  }
  get isSupperAdmin() {
    let user = this.getCurrentUser();
    return user && (user.roles.includes(Role.SupperAdmin.toString()));
  }
  jwtHelper: JwtHelperService = new JwtHelperService();
    redirectUrl: string;
  keepAlive(){
    const url = environment.serverUrl + 'api/users/keepAlive' ;
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, {observe:'response', withCredentials: true });
  }
  setCurretUser(data: any) {
    localStorage.setItem('currentUser', JSON.stringify(data));
  }
  setPrefHideLogo(withLogo){
    localStorage.setItem('hideLogo', JSON.stringify(withLogo));
  }
  getPrefHideLogo(){
    return JSON.parse(localStorage.getItem('hideLogo'));
  }

  resetPassword(email: any) {
    let url = environment.serverUrl + 'api/users/resetPwd';
      url += '?login=' + email;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(url, null, {responseType:'text'});
  }
  changePassword(oldPassword: any, newPassword: any) {
    let url = environment.serverUrl + 'api/users/changePwd';
      url += '?oldPassword=' + oldPassword+'&newPassword='+newPassword+'&passwordConfirm='+newPassword;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(url, null, { headers, withCredentials: true });
  }
  public isAuthenticated(): boolean {
    const token = this.getToken();
    let tmp = !!token && !this.isTokenExpired();
    return tmp;
  }
  isTokenExpired (): boolean {
    const token = this.getToken();
    return this.jwtHelper.isTokenExpired(token);
  }
  getToken() {
    return JSON.parse(localStorage.getItem('jwttoken'));
  }
  removeToken() {
    localStorage.removeItem('jwttoken');
  }
    // isLoggedIn = false;
    login(username: string, password: string) {
      let url = environment.serverUrl+"login";
      let formData= {};
        if (password && username) {
            formData['username']= username;
            formData['password']= password;

        }

        let headers = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(url, formData,{ headers,withCredentials: true})

    }

    logout() {
      const url = environment.serverUrl + 'logout';
      localStorage.removeItem('jwttoken');
      this.router.navigate(['/login']);

    }
    getCurrentUser() {
      let user;
      let token = localStorage.getItem('jwttoken');
        if(!this.jwtHelper.isTokenExpired(token)){
          let decoToken = this.jwtHelper.decodeToken(token)
          user = decoToken['user'];
        }
        return user;
    }
  //   getUserLoggedIn() {
  //   return JSON.parse(localStorage.getItem('currentUser'));
  // }
  getUserApiTargetUrl(){
    let subUrl;
    if(this.isUser){
      subUrl = 'user';
    }
    if(this.isMarchant){
      subUrl = 'merchant';
    }
    if(this.isAgent){
      subUrl = 'asvp';
    }
    if(this.isAdmin){
      subUrl = 'admin';
    }
    if(this.isSupperAdmin){
      subUrl = 'superadmin';
    }
    return subUrl;
  }
}
