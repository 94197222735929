import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRASComponent } from '../modal-ras/modal-ras.component';

@Component({
  selector: 'app-resset-login',
  templateUrl: './resset-login.component.html',
  styleUrls: ['./resset-login.component.scss']
})
export class RessetLoginComponent implements OnInit {
  model: any = {};
  msg: string;
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    public router: Router) {

    }

  ngOnInit() {

  }
  resetPassword () {
    this.authenticationService.resetPassword(this.model.login)
    .subscribe(
     data => {

        const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
        modalRef.componentInstance.title = 'Confirmation';
        modalRef.componentInstance.message = 'Votre mot de passe a été envoyé';

        modalRef.result.then(result => {
          if (result == true) {
            this.router.navigate(['/home']);
          }
        });
     },
     error => {

          this.msg = 'L\'utilisateur est introuvable';
        this.alertService.error(this.msg);
        },
     () => {
     });
  }


}
