import { Component, OnInit } from '@angular/core';
import { ParkingService } from '../services/parking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormChangeService } from '../services/form-change.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddPlaceComponent } from '../zones/zone-update/add-place/add-place.component';
import { AlertService } from '../services/alert-service.service';

@Component({
  selector: 'app-conf-places',
  templateUrl: './conf-places.component.html',
  styleUrls: ['./conf-places.component.scss']
})
export class ConfPlacesComponent implements OnInit {
  lstPlaces: [];
  configId;
  config: any;
  configPlaces = new Array<any>();
  configPlacesOrig = new Array<any>();
  configPlacesUnlink = new Array<any>();
  constructor(private parrkingService: ParkingService,
              private formChangeService: FormChangeService,
              private modalService: NgbModal,
              protected alertService: AlertService,
              public router: Router,
              private route: ActivatedRoute) {
      this.route.params.subscribe( params => this.loadConfig(params) );
    }
  ngOnInit() {
  }
  loadConfig(data) {
    this.configId = data['id'];
    if (this.configId) {
      this.parrkingService.getMyConfigurations(this.configId).subscribe(
        data => {
          this.config = data;
          this.parrkingService.getMyPlacesById().subscribe(
            (param) => {
              debugger;
              this.lstPlaces = param;
                this.lstPlaces.filter(el => el['configurationId'] == this.config.id).forEach(
                  el => this.configPlaces.push(el)
                )
                this.configPlacesOrig = this.configPlaces;
              }
          )
        });

      }
    }
    save(){
      debugger;
      if(this.configPlaces.length >0 ){
          this.configPlaces.forEach(el => {
            this.parrkingService.linkPlace(this.config.id, el.id).subscribe(
              (data) =>{
              }
            );
          });
        }
      if(this.configPlacesUnlink.length >0){
        this.configPlacesUnlink.forEach(el => {
          this.parrkingService.unlinkPlace(this.config.id, el.id).subscribe(
            (data) =>{
            }
          );
        });
      }

      this.alertService.success("Configuration Mise à jour", true);
      this.router.navigate(['/configurations']);
    }
    addPlaces(){

      const modalRef = this.modalService.open(AddPlaceComponent, { backdrop: 'static', keyboard: false});
      modalRef.componentInstance.lstplace = this.lstPlaces.filter(el => el['configurationId'] != this.config.id);
      modalRef.result.then(data => {
        if (data) {
          let selectedPlaces = data as Array<any>;
          this.lstPlaces.filter(
            el => selectedPlaces.includes(el['id'])
          ).forEach(el => this.configPlaces.push(el));

        }
      });
    }
    deletePlace(place) {
      debugger;
      let tmp = this.configPlaces.filter(el => el['id'] != place.id);
      this.configPlaces = tmp;
      if(this.configPlacesOrig.length > 0 ){
        this.configPlacesOrig.forEach(el =>{
          if(el.id == place.id
            && this.configPlacesOrig.indexOf(place) >= 0
            && this.configPlacesUnlink.indexOf(place.id) < 0) {
            //unlink d'une place
            this.configPlacesUnlink.push(place.id);
          }
        })
      }

    }
}
