export class Utils {
  //used for fireFox compatibility
  validNumber(e){
    const keyValue = e.key;
    if (!e.target.value && keyValue == 0) {
        e.preventDefault();
    } else {
        if (!/[0-9.]/.test(keyValue)) {
            e.preventDefault();
        } else {
            if (/[0-9]{1,2}[.]/.test(e.target.value) && keyValue == ".") {
                e.preventDefault();
            }
            else if (/[0-9]{1,2}[.]/.test(e.target.value)) {
                if (/[0-9]{1,2}[.][0-9]{2}/.test(e.target.value)) {
                    e.preventDefault();
                }
            }
            else if (/[0-9]{3}/.test(e.target.value) && keyValue != ".") {
                    e.target.value += ".";
                if (/[0-9]{3}[.][0-9]{2}/.test(e.target.value)) {
                    e.preventDefault();
                }
            }

        }

    }
  }
}
