import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  constructor(public authenService: AuthenticationService,public router: Router) { }

  ngOnInit() {
  }

  get currentUser() {
      let tmp = this.authenService.getCurrentUser();
      return tmp;
  }

  changePass(){
     this.router.navigate(['/loginChange']);
  }

  profil(){
     this.router.navigate(['/profil']);
  }

  params(){
     this.router.navigate(['/params']);
  }

  logout() {
    this.authenService.logout();
}
}
