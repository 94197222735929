import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Role } from 'src/app/class/role';
import { CustomerService } from 'src/app/customer/customer.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/user/user.service';
import { ZonesService } from 'src/app/zones/zones.service';
import { DisplayService } from '../display.service';

@Component({
  selector: 'app-display-create',
  templateUrl: './display-create.component.html',
  styleUrls: ['./display-create.component.scss']
})
export class DisplayCreateComponent implements OnInit {
  userCreateForm: FormGroup;
  addressObject;
  submitted = false;
  searchText;
  selectedZones;
  lstZones: Array<any>;
  customerId;
  tenantId;
  selectedCivilite: string = null;
  valeurAcorriger = [];
  userForm: any;
  selectedCustomer;
  roles = new Array();
  selectedRoles = new Array();
  selectedTenant: any[];
  tenants: Object;
  @ViewChild('addresstext', { static: false }) addresstext: any;

  constructor(protected customerService: CustomerService,
    protected displayServ: DisplayService,
    private fb: FormBuilder,
    private tenantServ: TenantService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    private zoneServ: ZonesService,
    public activeModal: NgbActiveModal) {

      Object.keys(Role).forEach(rl =>{
        this.roles.push(Role[rl]);
      });

     }

  ngOnInit(): void {
    this.initForm();
  }
  fillAdressElements(tmp){
    if(this.addressObject){
      this.addressObject.address_components.forEach(element => {
        let type = element.types[0];
        if("postal_code" == type){
          tmp['cp'] = element.long_name;
        }
        if("locality" == type){
          tmp['city'] = element.long_name;
        }
      });
      tmp['latitude'] = this.addressObject.geometry.location.lat();
      tmp['longitude'] = this.addressObject.geometry.location.lng();
      tmp['adress']  = this.addressObject.name;
    }
  }
  getAddress(place: any) {
    this.addressObject = place;
  }
  initForm() {
    this.userCreateForm = this.fb.group({
      active: [],
      adress: new FormControl(""),
      city: [],
      cp: [],
      label: [],
      latitude: [],
      longitude: [],
      tenantId: [],
      ip: [],
      brand: [],
      port: [],
      username: [],
      password: [],
      automatic: []
    });
    this.rechercher();
  }
  get f() { return this.userCreateForm.controls; }



  private createFromForm() {
    let tmp = {
      active: this.userCreateForm.get(['active']).value,
      label: this.userCreateForm.get(['label']).value,
      ip: this.userCreateForm.get(['ip']).value,
      brand: this.userCreateForm.get(['brand']).value,
      port: this.userCreateForm.get(['port']).value,
      username: this.userCreateForm.get(['username']).value,
      password: this.userCreateForm.get(['password']).value,
      automatic: this.userCreateForm.get(['automatic']).value,
    };
    tmp['zoneIds']= this.selectedZones;
    if (!this.authenSer.isAdmin) {
      tmp['tenantId'] = this.userCreateForm.get(["tenantId"])!.value;
    }
    this.fillAdressElements(tmp);
    return tmp;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.userCreateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();

      this.subscribeToSaveResponse(this.displayServ.saveDisplays(this.userForm));
    }
  }
  valid(f){

    return f.valid;
  }
  rechercher() {
    let filter = this.getFilter();
    if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
        }
        , error => {
          console.log(error);
        });
    }
    this.zoneServ.getZones().subscribe(
      (zones)=>{
        if(zones){
          this.lstZones = zones as Array<any>;
        }
      }
    )
  }

  getCustomersFromBackEnd(filter) {
    return this.customerService.getCustomers();
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Utilisateur sauvegardé !', true);
    this.formService.raisereloadSearchRequestDisplayEvent();
    this.close();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', true);
  }
  private getFilter() {

    let filter = this.searchText ? this.searchText : null;
    return filter;
  }
}
