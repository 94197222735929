import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot,Route } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = this.getUser();
    ;
    if (user) {
      if (route.data.roles) {
        let found = false;
        route.data.roles.forEach(authoRisedRole => {
          let ind =user.roles.indexOf(authoRisedRole);
          if(ind >= 0){
            found = true;
            return;
          }
        });
        if (!found) {
          this.router.navigate(['/']);
          return false;
        }
      }
      return true;
    }
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
  getUser(){
    return  this.authService.getCurrentUser();
  }
  canLoad(route: Route): boolean {
    let url = `/${route.path}`;
    if (this.checkLogin()) {
      // logged in so return true
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
      return false;
    }
  }
   checkLogin() {
    return this.authService.isAuthenticated();
  }

}
