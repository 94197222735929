import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { AlertService } from '../services/alert-service.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ModalRASComponent } from '../modal-ras/modal-ras.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from '../class/role';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model: any = {};
  returnUrl = '/home';
  constructor(public authenticationService: AuthenticationService,
              public alertService: AlertService,
              private modalService: NgbModal,
              public router: Router) { }
  ngOnInit(){

    if (this.authenticationService.isAuthenticated()){
      this.router.navigate(['/home']);
    }
  }
  login() {

      this.authenticationService.login(this.model.username, this.model.password)
      .subscribe(
        data => {
          let jwtHelper = new JwtHelperService();

          let tmpToken = JSON.stringify(data['jwttoken']);
          let decoToken = jwtHelper.decodeToken(tmpToken);
          let user = decoToken['user'];
          let regect = (user.roles.includes(Role.User.toString())) || (user.roles.includes(Role.Agent.toString()));
          if(regect){
            this.authenticationService.logout();
            const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static', keyboard: false});
            modalRef.componentInstance.title = 'Accès Refusé';
            modalRef.componentInstance.message = 'Pour accèder à l\'application, nous vous invitant à utiliser l\'application Mobile';
            modalRef.componentInstance.withCancel = false;
          } else {
            localStorage.setItem('jwttoken',JSON.stringify(data['jwttoken']));
            this.router.navigate(['home']);
            this.router.navigate([this.returnUrl]);
        }
        },
        error => {
          const msg = 'L\'identifiant ou le mot de passe est incorrect';
          this.alertService.error(msg);
        });
  }
}
