import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayService } from '../displays/display.service';
import { Civilitee } from '../enum/units.enum';
import { ModalRASComponent } from '../modal-ras/modal-ras.component';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { FormChangeService } from '../services/form-change.service';
import { ZoneCreateComponent } from './zone-create/zone-create.component';
import { ZoneUpdateComponent } from './zone-update/zone-update.component';
import { ZonesService } from './zones.service';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit {
  searchText;
  lstZones = [];
  lstCusomers = [];
  displayedColumns = ['label','NbrPlace','status','Actions'
  ];
  civiliteeEnum = Civilitee ;
  expandedElement: {} | null;

  getKeys(object): string[] {
    return Object.keys(object);
  }

  onItemSelected(idx: number) {
    console.log(idx);
  }

  constructor(private formService: FormChangeService,
    private authenSer: AuthenticationService,
    private alertService: AlertService,
    private zoneServ: ZonesService,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {
    this.subscribeformChange();
  }
  subscribeformChange() {
    this.formService.formZoneSearchEvent.subscribe(
      (param) => {

        this.lstZones = param;

      });

  }
  activateZone(el, event) {
    let enable = event.checked;
    let msg1, msg2;
    if(enable){
      msg1  = 'Zone Activé';
      msg2 = 'Erreur lors de l\'activation ';
    } else {
      msg1  = 'Zone Désactivé';
      msg2 = 'Erreur lors de la désactivation ';
    }
    this.zoneServ.enableZone(enable, el.id).subscribe(
      () => {
        this.onDeleteSuccess(msg1);
      },
      () => this.onDeleteError(msg2)
    );
  }
  create(): void {
    const modalRef = this.modalService.open(ZoneCreateComponent, { backdrop: 'static' });
  }
  editDisplay(zone) {
    const modalRef = this.modalService.open(ZoneUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.zone = zone;

  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'un Display";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.deleteDisplay(id);
      }
    });
  }

  deleteDisplay(id) {
    this.zoneServ.deleteZones(id).subscribe(
      () => {
        this.onDeleteSuccess('Suppression effectuée.');
      },
      () => this.onDeleteError('Erreur de suppression.')
    );

  }
  protected onDeleteSuccess(msg): void {
    this.alertService.success(msg, false);
    this.formService.raisereloadSearchRequestZoneEvent();
  }


  protected onDeleteError(msg): void {
    this.alertService.warn(msg, false);
  }

}
