import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommerceService } from '../commerces/commerce.service';
import { ModalRASComponent } from '../modal-ras/modal-ras.component';
import { AlertService } from '../services/alert-service.service';
import { FormChangeService } from '../services/form-change.service';
import { CouponService } from './coupon.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {
  coupons;
  constructor(private formService: FormChangeService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private couponServ: CouponService,
    private router: Router,
    private route: ActivatedRoute) { }

@ViewChild(MatSort, {static: true}) sort: MatSort;
    displayedColumns = [
    'expirationDate','note', 'usedBy',
    'actions',
    ];
    expandedElement: {} | null;
    sortData(sort: Sort) {

      const data = this.coupons;
      if (!sort.active || sort.direction === '') {
        return;
      }
      if (this.coupons) {
        this.coupons.data = data.data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
            // case 'dateCreation': return this.compare(a.dateCreation, b.dateCreation, isAsc);
            case 'expirationDate': return this.compare(a.expirationDate, b.expirationDate, isAsc);
            default: return 0;
          }
        });
    }
  }

   compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
    ngAfterViewInit() {
      if(this.coupons){
        this.coupons.sort = this.sort;
      }
    }
    ngOnInit() {
      this.subscribeformChange();
    }
    subscribeformChange() {
      this.formService.formCouponsSearchChanged.subscribe(
        (param) => {
          let data = param.forEach(element => {
            let m = element.timeParkFree % 60;
            let h = Math.floor(element.timeParkFree / 60);
            element['temps'] = h+'H '+ m+'min';
          });
          this.coupons = new MatTableDataSource(param);
        });

    }
    openDeleteConfirm(id): void {
      const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
      modalRef.componentInstance.title = "Suppresion d'un Coupon";
      modalRef.componentInstance.message = "Confirmer la suppression ?";
      modalRef.componentInstance.withCancel = true;
      modalRef.result.then(result => {
        if (result == true) {
          this.delete(id);
        }
      });
    }
    create(){
      this.router.navigateByUrl('/create-coupon');
    }
    delete(id) {
      this.couponServ.deleteCoupon(id).subscribe(
        () => this.onDeleteSuccess(),
        () => this.onDeleteError()
      );

    }
    edit(coupon){
      this.router.navigateByUrl('/update-coupon', { state: {coupon:coupon}});
    }
    protected onDeleteSuccess(): void {
      this.alertService.success('Suppression effectuée.', false);
      this.formService.raiseReloadCommersSearchRequestEvent();
    }


    protected onDeleteError(): void {
      this.alertService.warn('Erreur de suppression.', false);
    }
  }
