import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/authentication.service';

const apiUrl = environment.serverUrl + 'api/';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    deleteUser(id: any) {
        let subUrl = this.authenSer.getUserApiTargetUrl();
        let url = apiUrl + subUrl + '/users/' + id;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.delete(url);

    }

    saveUser(user): Observable<any> {
      let subUrl = this.authenSer.getUserApiTargetUrl();
        let url = apiUrl + subUrl + '/users';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(url, user);
    }

    getUser() {
        let subUrl =this.authenSer.getUserApiTargetUrl();
        let url = apiUrl + subUrl + '/users';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url);
    }

    putUser(user): Observable<any> {
        let subUrl = this.authenSer.getUserApiTargetUrl();
        let url = apiUrl + subUrl + '/users';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(url, user);
    }

    acceptCvg(): Observable<any> {
      let subUrl =this.authenSer.getUserApiTargetUrl();
      const url = apiUrl + subUrl + '/users/acceptContract';
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.post(url, null, { headers, withCredentials: true });
    }
    constructor(private http: HttpClient, private authenSer: AuthenticationService) { }
}
