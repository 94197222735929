export class Role {
  static  User = new Role('ROLE_USER', 'user');
  static Admin = new Role('ROLE_ADMIN', 'admin');
  static SupperAdmin = new Role('ROLE_SUPER_ADMIN', 'superadmin');
  static Marchant = new Role('ROLE_MERCHANT', 'marchand');
  static Agent = new Role('ROLE_ASVP', 'agent');

  private constructor(private readonly key: string, public readonly value: any) {
  }
  public static valueOf(str: string ){
    if(str === 'ROLE_USER'){
      return Role.User;
    }
    if(str === 'ROLE_ADMIN'){
      return Role.Admin;
    }
    if(str === 'ROLE_ASVP'){
      return Role.Agent;
    }
    if(str === 'ROLE_MERCHANT'){
      return Role.Marchant;
    }
    if(str === 'ROLE_SUPPERADMIN'){
      return Role.SupperAdmin;
    }
  }
  toString() {
    return this.key;
  }
}
