import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import {tap, catchError} from 'rxjs/operators'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRASComponent } from '../modal-ras/modal-ras.component';
@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {

  constructor(private auth: AuthenticationService,private modalService: NgbModal,
    public router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (this.auth.getCurrentUser()) {
        if (this.auth.isTokenExpired()) {
            this.router.navigateByUrl('/login');
        } else {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`
                }
            });
        }
    }
    return next.handle(request).pipe(
            tap(evt => {}),
            catchError(
                error => {

                    if (error.status === 401) {
                        localStorage.removeItem('currentUser');
                        //redirect to the signin page or show login modal here
                        this.router.navigateByUrl('/login');
                        }
                     if(error.status === 0) {
                        const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
                          modalRef.componentInstance.title = 'Maintenance en cours';
                          modalRef.componentInstance.message = 'Application en cours de maintenance, '+
                          '<br> nous vous invitons à vous reconnecter ultérieurement'
                          +'<br>Veuillez nous excuser pour la gêne occasionnée';
                          modalRef.componentInstance.withCancel = false;
                        return ;
                        }

                        return Observable.throw(error);

                }
                ));
            }

}
