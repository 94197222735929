import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdministrationComponent } from './administration/administration.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ConfPlacesComponent } from './conf-places/conf-places.component';
import { PlaceComponent } from './place/place.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { AuthGuard } from './Guard/AuthGuard';
import { RessetLoginComponent } from './resset-login/resset-login.component';
import { Role } from './class/role';
import { UserComponent } from './user/user.component';
import { MonProfileComponent } from './mon-profile/mon-profile.component';
import { AddCardModalComponent } from './credit-card-form/add-card-modal/add-card-modal.component';
import { StatsComponent } from './stats/stats.component';
import { MapComponent } from './map/map.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { ComptaComponent } from './compta/compta.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationUpdateComponent } from './notifications/notification-update/notification-update.component';
import { NotificationCreateComponent } from './notifications/notification-create/notification-create.component';
import { CommercesComponent } from './commerces/commerces.component';
import { CreateCommerceComponent } from './commerces/create-commerce/create-commerce.component';
import { UpdateCommerceComponent } from './commerces/update-commerce/update-commerce.component';
import { CouponsComponent } from './coupons/coupons.component';
import { UpdateCouponComponent } from './coupons/update-coupon/update-coupon.component';
import { CreateCouponComponent } from './coupons/create-coupon/create-coupon.component';
import { EquipeComponent } from './equipe/equipe.component';
import { DisplaysComponent } from './displays/displays.component';
import { ZonesComponent } from './zones/zones.component';
import { ZoneUpdateComponent } from './zones/zone-update/zone-update.component';
import { DashComponent } from './dash/dash.component';


const routes: Routes = [
  { path: 'home', component:  HomeComponent,
    canActivate: [AuthGuard]},
  { path: 'login', component:  LoginComponent},
  {
    path: 'loginResset', component: RessetLoginComponent
  },
  {path: 'users', component: UserComponent,
  canActivate: [AuthGuard],
  data: { roles: [Role.Admin.toString(),Role.SupperAdmin.toString()] }
  },
  {path: 'equipe', component: EquipeComponent,
  canActivate: [AuthGuard],
  data: { roles: [Role.Admin.toString()] }
  },
  {
  path: 'profil', component: MonProfileComponent,
  canActivate: [AuthGuard]
},
{
  path: 'add-card', component: AddCardModalComponent,
  canActivate: [AuthGuard]
},
{
  path: 'display', component: DisplaysComponent,
  canActivate: [AuthGuard],
  data: { roles: [Role.Admin.toString(), Role.SupperAdmin.toString()] }
},
{
  path: 'zones', component: ZonesComponent,
  canActivate: [AuthGuard],
  data: { roles: [Role.Admin.toString(), Role.SupperAdmin.toString()] }
},
{
  path: 'update-zone', component: ZoneUpdateComponent,
  data: { roles: [Role.Admin.toString(), Role.SupperAdmin.toString()] }
},
{
  path: 'stats', component: DashComponent,
  canActivate: [AuthGuard],
  data: { roles: [Role.Admin.toString(),Role.SupperAdmin.toString()] }
},{
  path: 'map', component: MapComponent,
},
  { path: 'administration',      component: AdministrationComponent,
  canActivate: [AuthGuard] },
  {path: 'configurations', component: ConfigurationsComponent,
  canActivate: [AuthGuard]},
  {path: 'configuration/:id', component: ConfigurationComponent,
  canActivate: [AuthGuard]},
  {path: 'configuration', component: ConfigurationComponent,
  canActivate: [AuthGuard]},
  {path: 'confPlaces/:id', component: ConfPlacesComponent,
  canActivate: [AuthGuard]},
  {path: 'place/:id', component: PlaceComponent,
  canActivate: [AuthGuard]},
  {path: 'confiuration/:configId/place', component: PlaceComponent,
  canActivate: [AuthGuard]},
  {path: 'place', component: PlaceComponent,
  canActivate: [AuthGuard]},
  {path: 'loginChange', component: ChangePassComponent},
  {
    path: 'compta', component: ComptaComponent
  },
  {
    path: 'events', component: NotificationsComponent
  },
  {
    path: 'update-notification', component: NotificationUpdateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'commerces', component: CommercesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Marchant.toString()] }
  },
  {
    path: 'create-commerce', component: CreateCommerceComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Marchant.toString()] }
  },
  {
    path: 'update-commerce', component: UpdateCommerceComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Marchant.toString()] }
  },
  {
    path: 'coupons', component: CouponsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Marchant.toString()] }
  },
  {
    path: 'create-coupon', component: CreateCouponComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Marchant.toString()] }
  },
  {
    path: 'update-coupon', component: UpdateCouponComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Marchant.toString()] }
  },
  {
    path: 'create-notification', component: NotificationCreateComponent,
    canActivate: [AuthGuard],
  },
  { path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
