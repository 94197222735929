import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class StoreSummaryService {
  getStoreSummary(): Observable<any[]> {
    return of([
      { title: "Moyenne Occupation", value: "9465", isIncrease: true, color: "primary", percentValue: "0.5383", icon: "timer", isCurrency: true },
      { title: "Moyenne Stationnement", value: "465", isIncrease: false, color: "accent", percentValue: "0.2544", icon: "alarm_on", isCurrency: true },
      { title: "Moyenne Depassement", value: "243", isIncrease: true, color: "warn", percentValue: "0.4565", icon: "alarm_off", isCurrency: false },
      { title: "Moyenne Libre", value: "35", isIncrease: false, color: "primary", percentValue: "0.8361", icon: "timer_off", isCurrency: false }
    ]);
  }
  constructor() { }
}
