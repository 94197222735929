import { Component, ElementRef, NgZone, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import {} from 'googlemaps';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EquipeService } from '../equipe/equipe.service';
import { ParkingService } from '../services/parking.service';
import { ZonesService } from '../zones/zones.service';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  constructor(private parrkingService: ParkingService,
     private ngZone: NgZone,
     private zoneServ: ZonesService,
     private equipeServ: EquipeService,
      private route: Router) {
    this.place = this.route.getCurrentNavigation().extras.state ? this.route.getCurrentNavigation().extras.state.place : null;

  }
  icons = {
    place: {
      icon_libre: '../../assets/p_blue.png',
      icon_red: '../../assets/p_red.png',
      icon_green: '../../assets/p_green.png'
    },
    agent: {
      icon: '../../assets/agentmap.png'
    },
  };
  placeMarkers = new Array<google.maps.Marker>();
  @ViewChild('map', { static: false }) mapElement: any;
  @ViewChild('search',  { static: false })
  public searchElementRef: ElementRef;
  map: google.maps.Map;
  _destroyed = new Subject();
  mapCenter ;
  lstPlaces = new Array<any>();
  @Input() place;
  @Input() agent;
  selectedTarget;
  selectedTargetType;
  targetTypes = [
    {id: 1, code:'zone', label: 'Zones'},
    {id: 2, code:'place', label: 'Places'}];
  targets;
  placeTypes = [
    {id: 1, code: 'PMR', label: 'PNR'},
    {id: 2, code: 'Paying', label: 'Payante'},
        {id: 3, code: 'Free', label: 'Gratuite'},
        {id: 4, code: 'Resident', label: 'Resident'},
        {id: 5, code: 'Private', label: 'Privée'},
        {id: 6, code: 'Rescue', label:'Urgence'},
        {id: 7, code: 'Delivering', label: 'Livraison'}];

  getPlaceIcon(place) {
    if(place.busy){
      const deadline =  moment(place['dateLimitBusy']);
      let before = moment(deadline).isBefore(moment());
      if(before){//Red
        return this.icons.place.icon_red;
      } else {//Green
        return this.icons.place.icon_green;
      }
    } else {
      return this.icons.place.icon_libre;
    }
  }
  ngOnInit() {
    this.parrkingService.getMapElements()
    .pipe(takeUntil(this._destroyed))
    .subscribe((data) => {
      this.lstPlaces = data['places'] as Array<any>;

      if (this.lstPlaces) {
        this.lstPlaces.forEach((el, index) => {
          el.active = false;
          if (index === 0) {
              this.mapCenter = new google.maps.LatLng(el.latitude, el.longitude);
          }

          this.placeMarkers.push( new google.maps.Marker({
            icon: {url:this.getPlaceIcon(el),
              scaledSize: new google.maps.Size(20, 20)},
            clickable: true,
            position: { lat: el.latitude, lng: el.longitude },
            title: 'Hello World!',
          }));

        });

      }
      let lstAgents = data['users'] as Array<any>;
      if (lstAgents) {
        lstAgents.forEach((el, index) => {
          el.active = false;
          this.lstPlaces.push(el);
          if (el.latitude && el.longitude ){
            let marker = new google.maps.Marker({
              position: { lat: el.latitude, lng: el.longitude },
              icon: {url:this.icons.agent.icon,
                  scaledSize: new google.maps.Size(25, 25)},
              title: el.firstname +' '+ el.name,
            });
            this.placeMarkers.push( marker);
            if (this.agent && el.id == this.agent.id) {
              this.mapCenter = new google.maps.LatLng(el.latitude, el.longitude);
            }
          }
        });
      }
      this.perimetreChange();
      this.initMap();
    });

  }
  perimetreChange() {

    this.getTargets();
  }
  getTargets() {
    if(this.selectedTargetType == "zone"){
      this.zoneServ.getZones().subscribe(
        (data) =>{
          this.targets = data;
        }
      )
    }
    if(this.selectedTargetType == "place"){
       this.targets = this.placeTypes;
    }
  }

  initMap() {
    this.map = new google.maps.Map(
      this.mapElement.nativeElement,
      {
        center: this.mapCenter,
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }
    );
    this.placeMarkers.forEach(
      (marker, index) => {
        marker.setMap(this.map);

        if(marker.getIcon() != this.icons.agent.icon) {
          let infoWindow = new google.maps.InfoWindow();
          marker.addListener('click',((tempMarker, map, infoWindow) => {
          return () => {
            let popup = "<div> ";
            popup += ""
            popup +=""
            popup += " </div>"
            infoWindow.setContent('<p><b>Place' + this.lstPlaces[index].busy? 'Occupée':'Libre' + '</b>');
            infoWindow.open(map, tempMarker);
            }
          })(marker, this.map, infoWindow));
        }
      }
    );

    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {

          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          } else {
            this.map.panTo(
              new google.maps.LatLng(
                place.geometry.location.lat(),
                place.geometry.location.lng()
              )
            );
          }
        });
      });
  }
  centerMapTo(place) {
    this.lstPlaces.forEach((el, index) => {
      if(el == place){
        el.active = true ;
      } else {
        el.active =  false ;
      }
    })
    // place.active = !place.active ;
    if(place.latitude && place.longitude ) {
        this.map.panTo(
          new google.maps.LatLng(
            place.latitude,
            place.longitude
          )
        );
      }
  }

}
