import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as moment from 'moment';
import { MomentPipe } from '../moment-pipe.pipe';
import { StatsService } from '../stats/stats.service';
import * as pluginDataLabels from "chartjs-plugin-datalabels";
@Component({
  selector: 'app-compta',
  templateUrl: './compta.component.html',
  styleUrls: ['./compta.component.scss']
})
export class ComptaComponent implements OnInit {

  public chartColors: Array<any> = [
    {
      backgroundColor: '#ffffff',
      borderColor: '#f74820',
      borderWidth: 2,
    },
    {
      backgroundColor: '#ffffff',
      borderColor: '#000044',
      borderWidth: 2,
    }
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true
          }
      }]
  },
  legend: {
    display: true,
    position: 'top'
  },
  elements:{
    line:{
      fill:false,
    }
  },
  title: {
    display: false,
    text: 'Historique Stationnement',
    position: 'bottom'
  },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };
  historiqueEmployeeChartOptions:ChartOptions ;
  historiqueCustomerChartOptions:ChartOptions ;

  barChartType: ChartType = "line";
  barChartPlugins = [pluginDataLabels];

  labelsHistoriqueTimeOccupation = [];
  labelTimeBusy = "Temps occupé";
  labelTimeFree = "Temps libre";
  labelDisponibilite = "Disponibilité";

  labelsHistoriqueCustomer = [];
  labelsHistoriqueEmployee = [];
  labelsHistoriqueTimeOccupation2 = "Nb stationnement";
  labelRevenus = "Revenus";
  employeeParDossier = "Tx production";

  chartPeriodCouranteData: ChartDataSets[]= new Array();
  chartHistoriqueEmployeeData: ChartDataSets[]= new Array();
  chartHistoriqueCustomerData: ChartDataSets[]= new Array();
  currentPeriod: any;
  currentPeriodStats: any;
  nbParkForDay = 21;
  meanTimeHourDay = 55;
  depacementMoyen = 22;
  nbFps = 2;

  recetteJ = 130;
  recetteM = 2013;
  depacementMoyenM = 35;
  nbFpsM = 19;
  constructor(private momentPipe: MomentPipe,
     private statsSrv: StatsService) {}

     exportReleve(){}
     addRib(){}
     demandeVir(){}
  ngOnInit(): void {
  }
}
