import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert-service.service';
import { ModalRASComponent } from '../modal-ras/modal-ras.component';

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent implements OnInit {
  model: any = {};
  msg: string;
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    public router: Router) {

    }

  ngOnInit() {
  }
  resetPassword () {
    this.authenticationService.changePassword(this.model.oldPassword,this.model.newPassword)
    .subscribe(
     data => {
        const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
        modalRef.componentInstance.title = 'Confirmation';
        modalRef.componentInstance.message = 'Votre mot de passe est modifié';
        modalRef.componentInstance.withCancel = true;
        modalRef.result.then(result => {
          if (result == true) {
            this.router.navigate(['/home']);
          }
        });
     },
     error => {
          this.msg = 'L\'utilisateur est introuvable';
        this.alertService.error(this.msg);
        },
     () => {
     });
  }
}
