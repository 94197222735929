import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() title = `Information`;
  @Input() idPlace;
  addedTimePicker: NgbTimeStruct ;
  constructor(
    public activeModal: NgbActiveModal
  ) {
    this.addedTimePicker = {hour: 0, minute: 0, second: 0};
  }

  validate() {
    this.activeModal.close(this.addedTimePicker.hour*60 + this.addedTimePicker.minute);
  }
  ngOnInit() {
  }

}
