import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  statTypes = [
    { id: 1, name: 'Journalière', label: 'Aujourd\'huit' },
    { id: 2, name: 'Hebdomadaire', label: 'Semaine' },
    { id: 3, name: 'Mensuelle', label: 'Mois' },
    { id: 4, name: 'Annuelle', label: 'Année' }
  ];
  stattype;
  barChartOptions: ChartOptions = {
    responsive: true
  };
  selectChange() {
    if(this.stattype == 'Journalière'){
      this.barChartData = this.barChartDataD;
      this.barChartLabels = ['Aujourd\'huit'];
    }
    if(this.stattype == 'Hebdomadaire'){
      this.barChartData = this.barChartDataH;
      this.barChartLabels = ['Semaine'];
    }
    if(this.stattype == 'Mensuelle'){
      this.barChartData = this.barChartDataM;
      this.barChartLabels = ['Mois'];

    }
    if(this.stattype == 'Annuelle'){
      this.barChartData = this.barChartDataA;
      this.barChartLabels = ['Année'];

    }
  }
barChartData :ChartDataSets[] ;
barChartLabels: Label[] ;
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartDataD: ChartDataSets[] = [
    { data: [6], label: 'Nbr Place Ocuppée' },
    { data: [50], label: 'Temps Occuppation Moyen' },
    { data: [28], label: 'Temps vacance Moyen' },
    { data: [2], label: 'Nbr depacement' },
    { data: [30], label: 'Moyenne depacement' }
  ];
  barChartDataH: ChartDataSets[] = [
    { data: [16], label: 'Nbr Place Ocuppée' },
    { data: [30], label: 'Temps Occuppation Moyen' },
    { data: [40], label: 'Temps vacance Moyen' },
    { data: [20], label: 'Nbr depacement' },
    { data: [30], label: 'Moyenne depacement' }
  ];
  barChartDataM: ChartDataSets[] = [
    { data: [6], label: 'Nbr Place Ocuppée' },
    { data: [50], label: 'Temps Occuppation Moyen' },
    { data: [28], label: 'Temps vacance Moyen' },
    { data: [2], label: 'Nbr depacement' },
    { data: [30], label: 'Moyenne depacement' }
  ];
  barChartDataA: ChartDataSets[] = [
    { data: [10], label: 'Nbr Place Ocuppée' },
    { data: [50], label: 'Temps Occuppation Moyen' },
    { data: [28], label: 'Temps vacance Moyen' },
    { data: [22], label: 'Nbr depacement' },
    { data: [10], label: 'Moyenne depacement' }
  ];
  constructor() {}

  ngOnInit() {
    this.stattype  = 'Journalière';
    this.selectChange();
  }
}
