import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CommerceService } from 'src/app/commerces/commerce.service';
import { MomentPipe } from 'src/app/moment-pipe.pipe';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ParkingService } from 'src/app/services/parking.service';
import { CouponService } from '../coupon.service';

@Component({
  selector: 'app-update-coupon',
  templateUrl: './update-coupon.component.html',
  styleUrls: ['./update-coupon.component.scss']
})
export class UpdateCouponComponent implements OnInit {
  couponCreateForm: FormGroup;
  coupon;
  constructor(
    private fb: FormBuilder,
    private digicodService: ParkingService,
    protected alertService: AlertService,
    private couponServ: CouponService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private formService: FormChangeService,
    private route: Router,
    private momentPipe: MomentPipe,
    private config: NgbTimepickerConfig
  ) {
    config.seconds = false;
    config.spinners = false;
    this.coupon = this.route.getCurrentNavigation().extras.state?this.route.getCurrentNavigation().extras.state.coupon:null;

  }

  ngOnInit(): void {
    this.initForm();
    if(this.coupon){
      this.updateForm(this.coupon);

    }
  }
  updateForm(variable: any): void {
    let m = variable.timeParkFree % 60;
    let h = Math.floor(variable.timeParkFree / 60);

    this.couponCreateForm.patchValue({
      expirationDate : moment(variable.expirationDate).format('YYYY-MM-DD'),
      note: variable.note,
      TimeStart: {hour: h, minute: m},
      id : variable.id,
      merchantId: variable.merchantId,
      userId: variable.userId,
    });

  }
  initForm() {
    this.couponCreateForm = this.fb.group({
      id :new FormControl(''),
      expirationDate :new FormControl(''),
      merchantId :new FormControl(''),
      timeParkFree :new FormControl(''),
      note:new FormControl(''),
      TimeStart: new FormControl({hour: 0, minute: 0}, Validators.required),
    });

  }

  private createFromForm(): any {
    let tmp = {
      expirationDate:this.couponCreateForm.get(["expirationDate"]).value?this.couponCreateForm.get(["expirationDate"]).value:null,
      note: this.couponCreateForm.get(["note"]).value?this.couponCreateForm.get(["note"]).value:null,
    };
    let tmsl = this.couponCreateForm.controls.TimeStart;
    let hourStart =	tmsl!.value!.hour;
    let  minStart = tmsl!.value!.minute;
    tmp['timeParkFree'] = hourStart* 60 + minStart;
    tmp['merchantId'] = this.coupon.merchantId;
    tmp['id'] = this.coupon.id;
    tmp['userId'] = this.coupon.userId;
    return tmp;
  }

  save(): void {
    let commerceToSave = this.createFromForm();
    this.couponServ
      .saveCoupon(commerceToSave)
      .subscribe(
        (data) => {
          this.alertService.success("Coupon Sauvegardé !", true);
          this.formService.raiseReloadCouponsSearchRequestEvent();
          this.route.navigateByUrl("/coupons");
        },
        (error) => {
          console.log(error);
          this.alertService.error("Erreur dans le formulaire !", true);
        }
      );
  }
}
