import { Component, OnInit } from '@angular/core';
import { ParkingPlace } from '../class/parking-place';
import { stringify } from 'querystring';
import { TypePlace } from '../class/type-place.enum';
import { ParkingService } from '../services/parking.service';
import { filter } from 'minimatch';
import { Router } from '@angular/router';
import { FormChangeService } from '../services/form-change.service';
import { AlertService } from '../services/alert-service.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  lstPlaces = [];
  filter: any;
  constructor(private parkingService: ParkingService,
              private alertService: AlertService,
              private formChangeService: FormChangeService,
              public router: Router) { }

  ngOnInit() {
    this.subscribeformChange();
  }
  subscribeformChange() {
    this.formChangeService.formPlacesChanged.subscribe(
        (param) => {
          this.lstPlaces = param;
   });

    }
}
