import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ParkingService } from 'src/app/services/parking.service';
import { TenantService } from 'src/app/services/tenant.service';
import { ZonesService } from '../zones.service';

@Component({
  selector: 'app-zone-update',
  templateUrl: './zone-update.component.html',
  styleUrls: ['./zone-update.component.scss']
})
export class ZoneUpdateComponent implements OnInit {
  commercesCreateForm: FormGroup;
  keys = Object.keys;
  zone : any;
  lstplace: Array<any>;
  tenants: Array<any>;
  selectedPlaces= new Array<any>();
  constructor(
    private fb: FormBuilder,
    private parkingServ: ParkingService,
    protected alertService: AlertService,
    private zoneServ: ZonesService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private formService: FormChangeService,
    private route: Router,
    private modalService: NgbModal,
    private tenantServ: TenantService
  ) {
  }
  close(): void {
    this.activeModal.close();
  }
  ngOnInit(): void {
    this.initForm();
    if (this.zone) {
      this.updateForm(this.zone);
    }

    this.rechercher();
  }
  valid(form){
    return form.valid;
  }
  rechercher() {
    this.parkingServ.getMyListPlaceByFilter().subscribe(
      (res) => {
        this.lstplace = res as Array<any>;
        this.lstplace.forEach(el =>{
          el.label = el.street + ', Place N° : '+ el.label;
        });
        if(this.authenSer.isSupperAdmin){
          this.tenantServ.getTenants()
          .subscribe(
            data => {
              this.tenants = data as Array<any>;
            }
            , error => {
              console.log(error);
            });
        }
      },
      (error) =>{
      }
    )
  }

  initForm() {
    this.commercesCreateForm = this.fb.group({
      id: [],
      tenantId: [],
      label: new FormControl(""),
      active: new FormControl(""),
      places: new FormArray([]),
    });

  }
  updateForm(zone: any): void {
    this.commercesCreateForm.patchValue({
      id: zone.id,
      tenantId: zone.tenantId,
      active: zone.active,
      label: zone.label,
      places: zone.places,
    });
    if(zone.places && zone.places.length >0){
      let tmp  = zone.places;
      tmp.forEach(el =>{
        el.label = el.street + ', Place N° : '+ el.label;
      });
      tmp.forEach(el =>{
        this.selectedPlaces.push(el.id);
      });
    }
  }
  private createFromForm(): any {
    let tmp = {
      id: this.zone.id,
      label: this.commercesCreateForm.get(["label"]).value,
      places: this.zone.places,
    };
    if (!this.authenSer.isAdmin) {
      tmp['tenantId'] = this.commercesCreateForm.get(["tenantId"])!.value;
    } else {
      tmp['tenantId'] = this.zone.tenantId;
    }
    return tmp;
  }

  onSubmit(): void {
    let zoneToSave = this.createFromForm();
    this.zoneServ
      .saveZones(zoneToSave,this.selectedPlaces)
      .subscribe(
        (data) => {
          this.alertService.success("Zone Sauvegardé !", true);
          this.formService.raisereloadSearchRequestZoneEvent();
          this.close();
        },
        (error) => {
          console.log(error);
          this.alertService.error("Erreur dans le formulaire !", true);
          this.close();
        }
      );
  }

}
