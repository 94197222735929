import { Component, Input, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from '../../customer/customer.service';
import { AlertService } from '../../services/alert-service.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FormChangeService } from '../../services/form-change.service';
import { UserService } from '../../user/user.service';
import { EquipeService } from '../equipe.service';

@Component({
  selector: 'app-form-min-equipe',
  templateUrl: './form-min-equipe.component.html',
  styleUrls: ['./form-min-equipe.component.scss']
})
export class FormMinEquipeComponent implements OnInit {
  sites: {};
  selectedCustomer: string;
  customers;
  selectedSite: any[];

  selectAll: boolean;
  searchText: string;
  searchTextCust: string;
  newSearched = true;
  numeroMin: number;
  numeroMax: number;
  model: NgModel;
  countAnnonces;
  @Input() withPeriod: boolean;
  period: any;
  selectedPeriod: string;
  periodList: any[];
  constructor(
    private alertService: AlertService,
    private customerService: CustomerService,
    private authenSer: AuthenticationService,
    private formService: FormChangeService,
    public equipeServ: EquipeService,
    public router: Router) {
  }


  ngOnInit() {
    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadSearchRequestEquipe.subscribe(
      () => {
        this.rechercher();
      });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private filterUsersByText(users) {
    let filter = this.getTextFilter();
    if (filter) {
      let text = filter.split(' ');

      return users.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = item['name'].toUpperCase().indexOf(el.toUpperCase()) > -1
            || item['firstname'].toUpperCase().indexOf(el.toUpperCase()) > -1
            || item['login'].toUpperCase().indexOf(el.toUpperCase()) > -1;
            return tmp;
        });
        return flt;
      });
    } else {
      return users;
    }
  }

  private getTextFilter() {
    return this.searchText;
  }
private loadCustomers() {
  this.getCustomersFromBackEnd()
      .subscribe(
        data => {
          this.customers = data as Array<any>;
        }
        , error => {
          console.log(error);
          this.formService.raiseCustomerListEvent({});
        });
}
rechercher() {

    this.getAgentsFromBackend()
      .subscribe(
        data => {
          let tmpUsers = data as Array<any>;
          if (this.selectedCustomer && this.selectedCustomer.length > 0) {
            tmpUsers = tmpUsers.filter(el => {
              let buff: any[] = [];
              buff[0] = this.selectedCustomer;

              return buff.find(cus => cus === el.customerId);
            });

          }
          tmpUsers = this.filterUsersByText(tmpUsers);

          this.formService.raiseFormMinEquiperSearch(tmpUsers);

        }
        , error => {

          console.log(error);
          this.formService.raiseFormMinEquiperSearch([]);

        });
  }


  getAgentsFromBackend() {
    return this.equipeServ.getAgents();
  }

  onFormChange() {
    this.rechercher();
  }

  getCustomersFromBackEnd() {
    return this.customerService.getCustomers();
  }

}
