import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-message',
  templateUrl: './add-message.component.html',
  styleUrls: ['./add-message.component.scss']
})
export class AddMessageComponent implements OnInit {

  @Input() title = `Information`;
  message ;
  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  validate() {
    this.activeModal.close(this.message);
  }
  ngOnInit() {
  }

}
