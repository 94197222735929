import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../services/alert-service.service';
import { FormChangeService } from '../services/form-change.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from './notifications.service';
import { ModalRASComponent } from '../modal-ras/modal-ras.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  constructor(private alertService: AlertService,
    private formService: FormChangeService,
    private modalService: NgbModal,
    private notificationServ: NotificationsService,
    private router: Router,
    private route: ActivatedRoute) { }
    givenInstallation;
    notifications = new MatTableDataSource(new Array());
    installationId;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    displayedColumns = [
    'dateCreation','type','eventType','nbExecution',
    'destValue',
    'eachOccurence',
    'nbNextOccurence',
    'actions',
    ];
    configurationTypes = [
      {id: 1, name: 'PMR', label: 'PMR'},
      {id: 2, name: 'Paying', label: 'Payante'},
      {id: 3, name: 'Free', label: 'Gratuite'},
      {id: 4, name: 'Resident',label: 'Résident'},
      {id: 5, name: 'Private', label: 'Privée'},
      {id: 6, name: 'Rescue', label: 'Pompier'},
      {id: 7, name: 'Delivering', label: 'Livraison'}
    ];
    typeEvent = [
      { id: 1, code: "BUSY", name: "place occupée" },
      { id: 2, code: "FREE", name: "place libérée" },
      {id: 3, code: "OUT_OF_TIME", name: "dépassement"},
    ];
    sortData(sort: Sort) {

      const data = this.notifications;
      if (!sort.active || sort.direction === '') {
        return;
      }

      this.notifications.data = data.data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'type': return this.compare(a.type, b.type, isAsc);
          case 'eventType': return this.compare(a.eventType, b.eventType, isAsc);
          case 'nbExecution': return this.compare(a.nbExecution, b.nbExecution, isAsc);
          case 'destValue': return this.compare(a.destValue, b.destValue, isAsc);
          case 'eachOccurence': return this.compare(a.eachOccurence, b.eachOccurence, isAsc);
          case 'nbNextOccurence': return this.compare(a.nbNextOccurence, b.nbNextOccurence, isAsc);
          default: return 0;
        }
      });
  }

   compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
    ngAfterViewInit() {
      this.notifications.sort = this.sort;
    }
    ngOnInit() {
      this.installationId  = this.route.snapshot.queryParams['id'];

      this.route.queryParams.subscribe(params => {
        this.installationId = params['id'];
        this.subscribeformChange();
      });
      this.notifications.sort = this.sort;
    }
    subscribeformChange() {
      this.formService.formNotificationsSearchChanged.subscribe(
        (param) => {
          let tmp = param as Array<any>;
          tmp.forEach(el => {
            let t = this.typeEvent.find((ty) => {return ty.code == el.eventType});
            el.typeEventLabel = t.name;
          });

          this.notifications = new MatTableDataSource(tmp);
        });

    }
    openDeleteConfirm(id): void {
      const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
      modalRef.componentInstance.title = "Suppresion d'une Notification";
      modalRef.componentInstance.message = "Confirmer la suppression ?";
      modalRef.componentInstance.withCancel = true;
      modalRef.result.then(result => {
        if (result == true) {
          this.delete(id);
        }
      });
    }
    create(){
      this.router.navigateByUrl('/create-notification');
    }
    delete(id) {
      this.notificationServ.deleteNotification(id).subscribe(
        () => this.onDeleteSuccess(),
        () => this.onDeleteError()
      );

    }
    edit(notif){
      this.router.navigateByUrl('/update-notification', { state: {notification:notif}});
    }
    protected onDeleteSuccess(): void {
      this.alertService.success('Suppression effectuée.', false);
      this.formService.raiseReloadProspectSearchRequestEvent();
    }


    protected onDeleteError(): void {
      this.alertService.warn('Erreur de suppression.', false);
    }
  }
