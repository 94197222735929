import { Component, OnInit } from '@angular/core';
import { ParkingService } from '../services/parking.service';
import { FormChangeService } from '../services/form-change.service';
import { Router } from '@angular/router';
import { AlertService } from '../services/alert-service.service';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit {
  lstConfigurations: any;

  constructor(private parkingService: ParkingService,
              private alertService: AlertService,
              private formChangeService: FormChangeService,
              public router: Router) { }

  ngOnInit() {
    this.subscribeformChange();
  }
  subscribeformChange() {
    this.formChangeService.formConfigsChanged.subscribe(
        (param) => {
          this.lstConfigurations = param;
   });

    }
}
