import { Component, OnInit, Input } from '@angular/core';
import { ParkingPlace } from '../class/parking-place';
import { TypePlace } from '../class/type-place.enum';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { Router } from '@angular/router';
@Component({
  selector: 'app-configuration-min',
  templateUrl: './configuration-min.component.html',
  styleUrls: ['./configuration-min.component.scss']
})
export class ConfigurationMinComponent implements OnInit {

  @Input()
  configuration: {};
  startDay: string;
  startTime: string;
  endTime: string;
  endDay: string;
  constructor(public router: Router) { }

  ngOnInit() {
    let ds = this.configuration['dayStart'];
    if (ds) {
      this.startDay = moment(ds).format('DD/MM/YYYY');
    }
    let hs: string = this.configuration['hourStart'];
    let ms: string = this.configuration['minStart'];
    let tmpStartTime: string;
    let tmpEndTime: string;
    if (hs) {
      tmpStartTime = hs;
    } else {
      tmpStartTime = '00';
    }
    if (ms) {
      tmpStartTime += ms;
    } else {
      tmpStartTime += '00';
    }
    if (hs || ms) {
      this.startTime = moment(tmpStartTime).format('HH[h]mm');
    }

    let de = this.configuration['dayEnd'];
    if(de){
      this.endDay = moment().format('DD/MM/YYYY');
    }
    let he: string = this.configuration['hourStart'];
    let me: string = this.configuration['minStart'];

    if (he) {
      tmpEndTime = he;
    } else {
      tmpEndTime = '00';
    }
    if (me) {
      tmpEndTime += me;
    } else {
      tmpEndTime += '00';
    }
    if (me || he) {
      this.endTime = moment(tmpEndTime).format('HH[h]mm');
    }

  }
  edit() {
    this.router.navigate(['/configuration', this.configuration['id']]);
  }
  addPlaces() {
    this.router.navigate(['/confPlaces', this.configuration['id']]);
  }
  get typeEnum() { return TypePlace; }
}
