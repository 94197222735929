import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-ras',
  templateUrl: './modal-ras.component.html',
  styleUrls: ['./modal-ras.component.scss']
})
export class ModalRASComponent implements OnInit {
  @Input() title = ``;
  @Input() message = ``;
  @Input() okBtnMsg;
  @Input() cancelBtnMsg;
  withCancel = false;
  constructor( public activeModal: NgbActiveModal) { }

  validate() {

    this.activeModal.close(true);
}


  ngOnInit() {
  }

}
