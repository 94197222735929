import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MomentPipe } from 'src/app/moment-pipe.pipe';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ParkingService } from 'src/app/services/parking.service';
import { CommerceService } from '../commerce.service';

@Component({
  selector: 'app-create-commerce',
  templateUrl: './create-commerce.component.html',
  styleUrls: ['./create-commerce.component.scss']
})
export class CreateCommerceComponent implements OnInit , OnDestroy {
  commercesCreateForm: FormGroup;
  keys = Object.keys;
  degicods;
  selectedCustomer: string = null;
  selectedCivilite: string = null;
  commerceToSave: any;
  emailInvalid: boolean;
  firstContact;
  lastContact;
  addressObject;
  genres = [
    {id: 1, code:'MADAME', name:'Madame'},
    {id: 2, code:'MADEMOISELLE', name:'Mademoiselle'},
    {id: 3, code:'MONSIEUR', name: 'Monsieur'}
  ];
  notification : any;
  constructor(
    private fb: FormBuilder,
    private digicodService: ParkingService,
    protected alertService: AlertService,
    private commerceServ: CommerceService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private formService: FormChangeService,
    private route: Router,
    private momentPipe: MomentPipe
  ) {
    this.notification = this.route.getCurrentNavigation().extras.state?this.route.getCurrentNavigation().extras.state.notification:null;
  }
  ngOnDestroy(): void {
  }
  refreshDestinataire(){
    this.commercesCreateForm.patchValue(
      {
        destValue: "",
      }
    );
  }
  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.commercesCreateForm = this.fb.group({
      adress :new FormControl(""),
      city: new FormControl(""),
      civilite:new FormControl(""),
      companyName:new FormControl(""),
      contactName:new FormControl(""),
      contactPosition:new FormControl(""),
      cp:new FormControl(""),
      email:new FormControl(""),
      tel1: new FormControl(""),
      tel2:new FormControl(""),
      siret:new FormControl(""),
    });

  }

  private createFromForm(): any {
    let tmp = {
      civilite:this.commercesCreateForm.get(["civilite"]).value?this.commercesCreateForm.get(["civilite"]).value:null,
      companyName:this.commercesCreateForm.get(["companyName"]).value?this.commercesCreateForm.get(["companyName"]).value:null,
      contactName:this.commercesCreateForm.get(["contactName"]).value?this.commercesCreateForm.get(["contactName"]).value:null,
      contactPosition:this.commercesCreateForm.get(["contactPosition"]).value?this.commercesCreateForm.get(["contactPosition"]).value:null,
      email:this.commercesCreateForm.get(["email"]).value?this.commercesCreateForm.get(["email"]).value:null,
      tel1: this.commercesCreateForm.get(["tel1"]).value?this.commercesCreateForm.get(["tel1"]).value:null,
      tel2:this.commercesCreateForm.get(["tel2"]).value?this.commercesCreateForm.get(["tel2"]).value:null,
      siret: this.commercesCreateForm.get(["siret"]).value?this.commercesCreateForm.get(["siret"]).value:null,
    };
    //fixeMe:
    tmp['offer']= "test";
    this.fillAdressElements(tmp);
    return tmp;
  }
  fillAdressElements(tmp){
    if(this.addressObject){
      this.addressObject.address_components.forEach(element => {
        let type = element.types[0];
        if("postal_code" == type){
          tmp['cp'] = element.long_name;
        }
        if("locality" == type){
          tmp['city'] = element.long_name;
        }
      });
      tmp['latitude'] = this.addressObject.geometry.location.lat();
      tmp['longitude'] = this.addressObject.geometry.location.lng();
      tmp['adress']  = this.addressObject.name;
    }
  }
  getAddress(place: any) {
    this.addressObject = place;
  }
  save(): void {
    this.commerceToSave = this.createFromForm();
    this.commerceServ
      .saveCommerce(this.commerceToSave)
      .subscribe(
        (data) => {
          this.alertService.success("Commerce Sauvegardé !", true);
          this.formService.raiseReloadCommersSearchRequestEvent();
          this.route.navigateByUrl("/commerces");
        },
        (error) => {
          console.log(error);
          this.alertService.error("Erreur dans le formulaire !", true);
        }
      );
  }

}
