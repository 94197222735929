import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdministrationComponent } from './administration/administration.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { MaterialModule } from './material/material.module';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ParkingPlaceComponent } from './parking-place/parking-place.component';
import { NotifierModule } from 'angular-notifier';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { AnnonceComponent } from './annonce/annonce.component';
import { FormMinAnnoceComponent } from './form-min-annoce/form-min-annoce.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { ConfigurationMinComponent } from './configuration-min/configuration-min.component';
import { FormMinConfigurationsComponent } from './form-min-configurations/form-min-configurations.component';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import {NgbModule, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ConfPlacesComponent } from './conf-places/conf-places.component';
import { PlaceComponent } from './place/place.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { ModalComponent } from './modal/modal.component';
import { AuthGuard } from './Guard/AuthGuard';
import { AlertService } from './services/alert-service.service';
import { AuthenticationService } from './services/authentication.service';
import { AlertComponent } from './alert/alert.component';
import { ModalRASComponent } from './modal-ras/modal-ras.component';
import { RessetLoginComponent } from './resset-login/resset-login.component';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { ConfigurationComponent } from './configuration/configuration.component';
import { UserComponent } from './user/user.component';
import { FormMinUserComponent } from './user/form-min-user/form-min-user.component';
import { MomentPipe } from './moment-pipe.pipe';
import { MonProfileComponent } from './mon-profile/mon-profile.component';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { AddCardModalComponent } from './credit-card-form/add-card-modal/add-card-modal.component';
import { AddSepaModalComponent } from './credit-card-form/add-sepa-modal/add-sepa-modal.component';
import { StatsComponent } from './stats/stats.component';
import { Utils } from './utils';
import { PrettyprintPipe } from './prettyprint.pipe';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MapComponent } from './map/map.component';
import { UserUpdateComponent } from './user/user-update/user-update.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { ComptaComponent } from './compta/compta.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsService } from './notifications/notifications.service';
import { FormMinNotificationsComponent } from './notifications/form-min-notifications/form-min-notifications.component';
import { NotificationCreateComponent } from './notifications/notification-create/notification-create.component';
import { NotificationUpdateComponent } from './notifications/notification-update/notification-update.component';
import { CommercesComponent } from './commerces/commerces.component';
import { FormMinCommercesComponent } from './commerces/form-min-commerces/form-min-commerces.component';
import { CreateCommerceComponent } from './commerces/create-commerce/create-commerce.component';
import { UpdateCommerceComponent } from './commerces/update-commerce/update-commerce.component';
import { AdresseComponent } from './adresse/adresse.component';
import { CouponsComponent } from './coupons/coupons.component';
import { FormMincouponsComponent } from './coupons/form-mincoupons/form-mincoupons.component';
import { CreateCouponComponent } from './coupons/create-coupon/create-coupon.component';
import { UpdateCouponComponent } from './coupons/update-coupon/update-coupon.component';
import { OffreComponent } from './commerces/offre/offre.component';
import { EquipeComponent } from './equipe/equipe.component';
import { FormMinEquipeComponent } from './equipe/form-min-equipe/form-min-equipe.component';
import { DisplaysComponent } from './displays/displays.component';
import { FormMinDisplaysComponent } from './displays/form-min-displays/form-min-displays.component';
import { DisplayCreateComponent } from './displays/display-create/display-create.component';
import { DisplayUpdateComponent } from './displays/display-update/display-update.component';
import { ZonesComponent } from './zones/zones.component';
import { ZoneCreateComponent } from './zones/zone-create/zone-create.component';
import { ZoneUpdateComponent } from './zones/zone-update/zone-update.component';
import { FormMinZoneComponent } from './zones/form-min-zone/form-min-zone.component';
import { ZonePlaceComponent } from './zones/zone-update/zone-place/zone-place.component';
import { DisplayMsgComponent } from './displays/display-msg/display-msg.component';
import { AddMessageComponent } from './displays/display-msg/add-message/add-message.component';
import { AddPlaceComponent } from './zones/zone-update/add-place/add-place.component';
import { ConfigPlaceComponent } from './conf-places/config-place/config-place.component';
import { DashComponent } from './dash/dash.component';
import { CardComponent } from './dash/card/card.component';
import { RadarChartComponent } from './dash/charts/radar-chart/radar-chart.component';
import { PieChartComponent } from './dash/charts/pie-chart/pie-chart.component';
import { LineChartComponent } from './dash/charts/line-chart/line-chart.component';
import { BarChartComponent } from './dash/charts/bar-chart/bar-chart.component';
import { MiniCardComponent } from './dash/mini-card/mini-card.component';
import { ChartModule } from 'angular2-chartjs';
import { FormMinDashComponent } from './dash/form-min-dash/form-min-dash.component';
import { RangesFooter } from './notifications/form-min-notifications/ranges-footer/ranges-footer.component';
import { DisplayConfigComponent } from './displays/display-config/display-config.component';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,FormMinUserComponent,UserUpdateComponent,UserCreateComponent,
    MomentPipe,MonProfileComponent,StatsComponent,
    AdministrationComponent,CreditCardFormComponent,
    NavBarComponent,
    AddCardModalComponent,
    AddSepaModalComponent,
    LoginComponent,
    ChangePassComponent,
    HomeComponent,
    ParkingPlaceComponent,
    AnnonceComponent,
    FormMinAnnoceComponent,
    FooterComponent,
    ConfigurationComponent,
    ConfigurationsComponent,
    ConfigurationMinComponent,
    FormMinConfigurationsComponent,
    ConfPlacesComponent,
    PlaceComponent,
    StatisticsComponent,
    ModalComponent,
    AlertComponent,
    ModalRASComponent,
    RessetLoginComponent,
    MapComponent,
    ComptaComponent,
    NotificationsComponent,
    NotificationsComponent,
    NotificationUpdateComponent,
    NotificationCreateComponent,
    FormMinNotificationsComponent,
    CommercesComponent,
    FormMinCommercesComponent,
    CreateCommerceComponent,
    UpdateCommerceComponent,
    AdresseComponent,
    CouponsComponent,
    FormMincouponsComponent,
    CreateCouponComponent,
    UpdateCouponComponent,
    OffreComponent,
    EquipeComponent,
    FormMinEquipeComponent,
    DisplaysComponent,
    FormMinDisplaysComponent,
    DisplayCreateComponent,
    DisplayUpdateComponent,
    DisplayMsgComponent,
    ZonesComponent,
    ZoneCreateComponent,
    ZoneUpdateComponent,
    FormMinZoneComponent,
    AddPlaceComponent,
    ZonePlaceComponent,
    AddMessageComponent,
    ConfigPlaceComponent,
    DashComponent,
    CardComponent,
    RadarChartComponent,
    PieChartComponent,
    LineChartComponent,
    BarChartComponent,
    MiniCardComponent,
    FormMinDashComponent,
    RangesFooter,
    DisplayConfigComponent,
  ],
  imports: [
    FormsModule,
        ReactiveFormsModule,
        NotifierModule,
        SatDatepickerModule, SatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MaterialModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    NotifierModule,
    FormsModule,
    NgSelectModule,
    HttpClientModule,
    NgbModule,
    ChartsModule,ChartModule,
    NgCircleProgressModule.forRoot({
      "backgroundColor": "#000044",
      "backgroundGradientStopColor": "#121fd9",
      "backgroundOpacity": 0,
      "backgroundStrokeWidth": 8,
      "radius": 100,
      "maxPercent": 100,
      "unitsFontSize": "17",
      "unitsFontWeight": "500",
      "unitsColor": "#148f29",
      "outerStrokeWidth": 10,
      "innerStrokeWidth": 5,
      "titleColor": "#148f29",
      "titleFontSize": "26",
      "titleFontWeight": "500",
      "subtitleColor": "#f74820",
      "subtitleFontWeight": "500",
      "subtitleFontSize": "16",
      "imageHeight": 72,
      "showBackground": false,
      "responsive": false,
      "startFromZero": false,
      "lazy": true})
  ],
  providers: [
    NgbActiveModal,
    AuthGuard,
    AlertService,Utils ,
    MomentPipe, PrettyprintPipe, ThemeService,NotificationsService,
    AuthenticationService,SatDatepickerModule, SatNativeDateModule,

    {
      provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptorService,
        multi: true
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UserUpdateComponent,
    UserCreateComponent,AddPlaceComponent,
    DisplayCreateComponent, DisplayUpdateComponent, DisplayConfigComponent,
    ZoneCreateComponent,DisplayMsgComponent,
    ModalComponent, OffreComponent,AddMessageComponent,
    ModalRASComponent,
    AddSepaModalComponent,
    RangesFooter,
  ]
})
export class AppModule { }
