import { Component, OnInit, Input } from '@angular/core';
import { ParkingPlace } from '../class/parking-place';
import { TimeFormatter } from '../class/time-formatter';
import * as moment from 'moment';
import { Observable, timer } from 'rxjs';
import { ParkingService } from '../services/parking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../services/alert-service.service';

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.scss']
})
export class PlaceComponent implements OnInit {
  @Input() placeId;
  place =  {};
  @Input()
  upperConfigId;
  subscription: any;
  types = [
    {id: 1, name: 'PMR'},
    {id: 2, name: 'Paying'},
        {id: 3, name: 'Free'},
        {id: 4, name: 'Resident'},
        {id: 5, name: 'Private'},
        {id: 6, name: 'Rescue'},
        {id: 7, name: 'Delivering'}];
  placeConfigId;
  countDown;
  countDownColor;
  everySecond: Observable<number> = timer(0, 1000);
  configs =[];
  constructor(private parrkingService: ParkingService,
          public alertService: AlertService,
              public router: Router,
              private route: ActivatedRoute) {

      this.placeId = this.route.snapshot.paramMap.get('id');
    }
  ngOnInit() {
    this.loadPlace();
    this.parrkingService.getMyConfigurations(null)
    .subscribe(
      data => {

        this.configs = data as Array<any>;
        if ( this.configs === undefined || this.configs.length == 0) {
          this.configs = this.configs.filter(conf => conf['active'] == true);
        }
        this.subscription = this.everySecond.subscribe((seconds) => {
          let currentTime: moment.Moment = moment();
          this.getCountDown(currentTime);
      });
      }
    , error => {
        let msg = error['error'] ? error['error']['error'] + ': ' : error;
        this.alertService.error(msg);

      });
  }
  loadPlace() {

    if (this.placeId) {
      this.parrkingService.getMyPlacesById(this.placeId).subscribe(
        data => {
          this.place = data;
          this.placeConfigId = data['configurationId'];
        },
        error =>{
          let msg = error['error'] ? error['error']['error'] + ': ' : error;
          this.alertService.error( msg);
        }
      );
    }
  }
  getCountDown(now) {
    let diff;
  if (this.place['busy']){
    let deadline = null;
    deadline =  moment(this.place['dateLimitBusy']);

    let before = moment(deadline).isBefore(now);
    if(before){//Red
       diff =moment(now).diff(deadline);
       let d = moment.duration(diff);
       this.countDown = (d.days() *24)+ d.hours()+'h '+d.minutes()+'m'+d.seconds();

       this.countDownColor = 'badge badge-pill badge-danger';
    } else {// Green
       diff =moment(deadline).diff(now);
       let d = moment.duration(diff);
       this.countDown = (d.days() *24)+ d.hours()+'h '+d.minutes()+'m'+d.seconds();
       this.countDownColor = 'badge badge-pill badge-success';
    }
  } else {
    let startFrom =  new Date(this.place['dateLastEmpty']).getTime();
    let now =moment.now();
    let after = moment(moment.now()).isSameOrAfter(startFrom);
    if(after){ //Green
       diff = moment(now).diff(startFrom);
       this.countDown = moment(diff).format('D[j] HH[h]mm:ss');
       this.countDownColor = 'badge badge-pill badge-success';
    }
  }
  }
  savePlace(){
    this.place['configurationId'] = this.placeConfigId;
    if (this.placeId) {
      this.parrkingService.editPlace(this.place).subscribe(
        data =>{
          this.router.navigate(['/administration']);
        },
        error => {
          console.log(error);
        }
      );

    } else {
      this.parrkingService.createPlace(this.place).subscribe(
        data =>{
          this.router.navigate(['/administration']);
        },
        error => {
          console.log(error);
        }
      );

    }
  }
}
