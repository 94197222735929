import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Role } from 'src/app/class/role';
import { CustomerService } from 'src/app/customer/customer.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ParkingService } from 'src/app/services/parking.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-add-place',
  templateUrl: './add-place.component.html',
  styleUrls: ['./add-place.component.scss']
})
export class AddPlaceComponent implements OnInit {
  userCreateForm: FormGroup;
  lstplace: Array<any>;
  selectedPlaces;
  zone;
  constructor(protected parkingServ: ParkingService,
    protected userService: UserService,
    private fb: FormBuilder,
    private tenantServ: TenantService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) {

     }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.userCreateForm = this.fb.group({
      places: [''],
    });
    if(this.lstplace){
      this.lstplace.forEach(el =>{
        el.label = el.street + ' Place N° : '+ el.label;
      });
    }

  }
  // get f() { return this.userCreateForm.controls; }



  private createFromForm() {
    // let tmp =  this.userCreateForm.get(['places']).value;
    return this.selectedPlaces;
  }

  onSubmit(): void {

    if (this.selectedPlaces.length > 0 ) {
      this.activeModal.close(this.selectedPlaces);
      // this.subscribeToSaveResponse(this.userService.saveUser(userForm));
    }
  }

  // rechercher() {
  //   // let filter = this.getFilter();
  //   this.parkingServ.getMyListPlaceByFilter().subscribe(
  //     (res) => {
  //       this.lstplace = res as Array<any>;
  //       this.lstplace.filter(el =>{
  //         this.zone.id
  //       })
  //     },
  //     (error) =>{
  //     }
  //   );
  // }


  close(): void {
    this.activeModal.close();
  }
}
