import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';
const apiUrl = environment.serverUrl + 'api/';
@Injectable({
  providedIn: 'root'
})
export class ZonesService {
  deleteZones(id: any) {
    let subUrl = this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/zones/' + id;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(url);

}

saveZones(zone, placeIds: Array<any>): Observable<any> {
  let subUrl = this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/zones';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if(placeIds.length > 0){
      placeIds.forEach((el, index) =>{
        if(index == 0){
          url += '?';
        } else {
          url += '&';
        }
        url += 'placeIds='+ el;
      })
    }

    if(zone.id){
      return this.http.put(url, zone)
    }
    else {
      return this.http.post(url, zone)
    };
}

getZones() {
    let subUrl =this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/zones';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
}

putZones(zone): Observable<any> {
    let subUrl = this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/zones';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(url, zone);
}
enableZone(enable, id){
  let subUrl = this.authenSer.getUserApiTargetUrl();
  let url = apiUrl + subUrl + '/zones';
  if(enable){
    url += '/enable';
  } else {
    url += '/disable';
  }
  url += '?zoneId='+ id;
  let headers = new HttpHeaders();
  headers = headers.append('Content-Type', 'application/json');
  return this.http.post(url, null);
}
constructor(private http: HttpClient, private authenSer: AuthenticationService) { }
}
