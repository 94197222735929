import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Role } from 'src/app/class/role';
import { CustomerService } from 'src/app/customer/customer.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ParkingService } from 'src/app/services/parking.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/user/user.service';
import { ZonesService } from 'src/app/zones/zones.service';
import { DisplayService } from '../display.service';

@Component({
  selector: 'app-display-update',
  templateUrl: './display-update.component.html',
  styleUrls: ['./display-update.component.scss']
})
export class DisplayUpdateComponent implements OnInit {

  userUpdateForm: FormGroup;
  addressObject;
  initialAdress;
  searchText;
  tenantId;
  userForm: any;
  selectedTenant;
  tenants: Object;
  display: any;
  // lstZones: Array<any>;
  // selectedZones;
  constructor(protected customerService: CustomerService,
    protected displayServ: DisplayService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    private zoneServ: ZonesService,
    public activeModal: NgbActiveModal,
    private tenantServ: TenantService,) { }

  ngOnInit(): void {

    this.initForm();
    this.updateForm(this.display);
    this.rechercher();
  }

  initForm() {
    this.userUpdateForm = this.fb.group({
      id: [],
      active: [],
      adress: new FormControl(""),
      city: [],
      cp: [],
      label: [],
      latitude: [],
      longitude: [],
      tenantId: [],
      ip: [],
      brand: [],
      username: [],
      port: [],
      password: [],
      automatic: []
    });

  }
  updateForm(display: any): void {
    this.initialAdress = display.adress, display.city;
    this.userUpdateForm.patchValue({
      customerId: display.customerId,
      id: display.id,
      tenantId: display.tenantId,
      active: display.active,
      adress: display.adress,
      label: display.label,
      latitude: display.latitude,
      longitude: display.longitude,
      ip: display.ip,
      brand: display.brand,
      username: display.username,
      port: display.port,
      password: display.password
    });
    // if(display.zoneIds && display.zoneIds.length >0){
    //   let tmp  = display.zoneIds;
    //   tmp.forEach(el =>{
    //     this.selectedZones.push(el.id);
    //   });
    // }

  }
  rechercher() {
    let filter = this.getFilter();
    if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
          if (tmp) {
            if (filter) {
                tmp = this.findTextInCustomers(tmp, filter);
              }
          }
          this.selectedTenant = tmp;
        }
        , error => {
          console.log(error);
        });
    }

  }
  getCustomersFromBackEnd(filter) {
    return this.customerService.getCustomers();
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  get f() { return this.userUpdateForm.controls; }

  getAddress(place: any) {
    this.addressObject = place;
  }

  private createFromForm(): any {
    let tmp = {
      id: this.display.id,
      adress : this.display.adress,
      latitude: this.display.latitude,
      longitude: this.display.longitude,
      cp: this.display.cp,
      city: this.display.city,
      tenantId: this.userUpdateForm.get(['tenantId']).value,
      active: this.userUpdateForm.get(['active']).value,
      label: this.userUpdateForm.get(['label']).value,
      brand: this.userUpdateForm.get(['brand']).value,
      ip: this.userUpdateForm.get(['ip']).value,
      username: this.userUpdateForm.get(['username']).value,
      port: this.userUpdateForm.get(['port']).value,
      password: this.userUpdateForm.get(['password']).value,
      automatic: this.userUpdateForm.get(['automatic']).value,
    };
    // tmp['zoneIds']= this.selectedZones;
    this.fillAdressElements(tmp);
    return tmp;
  }
  fillAdressElements(tmp){
    if(this.addressObject){
      this.addressObject.address_components.forEach(element => {
        let type = element.types[0];
        if("postal_code" == type){
          tmp['cp'] = element.long_name;
        }
        if("locality" == type){
          tmp['city'] = element.long_name;
        }
      });
      tmp['latitude'] = this.addressObject.geometry.location.lat();
      tmp['longitude'] = this.addressObject.geometry.location.lng();
      tmp['adress']  = this.addressObject.name;
    }
  }
  onSubmit(): void {
    // stop here if form is invalid
    if (this.userUpdateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();

      this.subscribeToSaveResponse(this.displayServ.putDisplays(this.userForm));
    }
  }

  private findTextInCustomers(customers, text) {
    text = text?text.split(' '):'';
    return customers.filter(function (item) {
      return text.every(function (el) {
        return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1
          || item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }

  protected onSaveSuccess(result): void {

    this.alertService.success('Utilisateur sauvegardé !', false);
    this.formService.raisereloadSearchRequestDisplayEvent();
    this.close();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', false);
  }
  private getFilter() {
    let filter = this.searchText ? this.searchText : null;
    return filter;
  }
}
