import { Component, OnInit } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import { MomentPipe } from '../moment-pipe.pipe';
import * as moment from 'moment';
import { StatsService } from './stats.service';

@Component({
  selector: "app-stats",
  templateUrl: "./stats.component.html",
  styleUrls: ["./stats.component.scss"],
})
export class StatsComponent implements OnInit {
  dateRange: Date ;
  moment = moment;

  public chartColors: Array<any> = [
    {
      backgroundColor: '#ffffff',
      borderColor: '#f74820',
      borderWidth: 2,
    },
    {
      backgroundColor: '#ffffff',
      borderColor: '#000044',
      borderWidth: 2,
    }
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true
          }
      }]
  },
  legend: {
    display: true,
    position: 'top'
  },
  elements:{
    line:{
      fill:false,
    }
  },
  title: {
    display: false,
    text: 'Historique Stationnement',
    position: 'bottom'
  },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };
  historiqueEmployeeChartOptions:ChartOptions ;
  historiqueCustomerChartOptions:ChartOptions ;

  barChartType: ChartType = "line";
  barChartPlugins = [pluginDataLabels];

  labelsHistoriqueTimeOccupation = [];
  labelTimeBusy = "Temps occupé";
  labelTimeFree = "Temps libre";
  labelDisponibilite = "Disponibilité";

  labelsHistoriqueCustomer = [];
  labelsHistoriqueEmployee = [];
  labelsHistoriqueTimeOccupation2 = "Nb stationnement";
  labelRevenus = "Revenus";
  employeeParDossier = "Tx production";

  chartPeriodCouranteData: ChartDataSets[]= new Array();
  chartHistoriqueEmployeeData: ChartDataSets[]= new Array();
  chartHistoriqueCustomerData: ChartDataSets[]= new Array();
  currentPeriod: any;
  currentPeriodStats: any;
  nbParkForDay = 21;
  meanTimeHourDay = 55;
  depacementMoyen = 22;
  nbFps = 2;

  nbParkMonth = 144;
  meanTimeHourM = 25;
  depacementMoyenM = 35;
  nbFpsM = 19;
  constructor(private momentPipe: MomentPipe,
     private statsSrv: StatsService) {}
  onFormChange() {
    // this.rechercher();
    this.rechercherStatMock();
  }
  rechercherStatMock(){
    let range = moment(this.dateRange).format('DD/MM/YYYY HH:mm:ss');
      // stats =>{
        let data = new Array<any>();

        this.clearChartsData();
        let d = moment(range).add(2,'day');
        data.push(d);
        data.forEach((el,index) =>{
          // this.fillCharts(el);
          if(index+1 == data.length){
            this.fillCurrentPeriodCharts(el);
          }
        });
      // }
    // );
  }
  rechercher() {
    // let nbrMois = moment(this.currentPeriod).endOf('month').diff(this.dateRange,"months");
    this.statsSrv.getStats(moment(this.dateRange).format('DD/MM/YYYY HH:mm:ss')).subscribe(
      stats =>{
        let data = stats as Array<any>;

        this.clearChartsData();
        data.forEach((el,index) =>{
          // this.fillCharts(el);
          if(index+1 == data.length){
            this.fillCurrentPeriodCharts(el);
          }
        });
      }
    );
  }
  fillCurrentPeriodCharts(el: any) {
    this.currentPeriodStats = el['stats'];
  }
  clearChartsData() {
    this.labelsHistoriqueTimeOccupation = [];
    this.labelsHistoriqueCustomer= [];
    this.chartHistoriqueEmployeeData.forEach(el =>{
      el['data'] = new Array();
    });
    this.chartHistoriqueCustomerData.forEach(el =>{
      el['data'] = new Array();
    });
  }
  private fillCharts(el: any) {
    this.labelsHistoriqueTimeOccupation.push(this.momentPipe.formatPeriodForDisplay(el.period));
    this.labelsHistoriqueCustomer.push(this.momentPipe.formatPeriodForDisplay(el.period));
    this.chartHistoriqueEmployeeData.filter(element => element.label == this.labelTimeBusy).map(
      chartElement => {
        chartElement['data'].push(el.stats.nbEmployeeTotal);
      });
    //ajout Employee Validé
    this.chartHistoriqueEmployeeData.filter(element => element.label == this.labelTimeFree).map(
      chartElement => {
        chartElement['data'].push(el.stats.nbEmployeeProcessed);
      });
    //ajout Soldes compte
    this.chartHistoriqueEmployeeData.filter(element => element.label == this.labelDisponibilite).map(
      chartElement => {
        chartElement['data'].push(el.stats.nbSoldeToutCompte);
      });
    //ajout total customer
    this.chartHistoriqueCustomerData.filter(element => element.label == this.labelsHistoriqueTimeOccupation2).map(
      chartElement => {
        chartElement['data'].push(el.stats.nbCustomerTotal);
      });
    //ajout customer Validée
    this.chartHistoriqueCustomerData.filter(element => element.label == this.labelRevenus).map(
      chartElement => {
        chartElement['data'].push(el.stats.nbCustomerProcessed);
      });
    //ajout Moyenne Employee Customer
    this.chartHistoriqueCustomerData.filter(element => element.label == this.employeeParDossier).map(
      chartElement => {
        chartElement['data'].push(el.stats.avgEmployeeByCustomer);
      });
  }

  ngOnInit(): void {
    this.historiqueEmployeeChartOptions = this.barChartOptions;
    this.historiqueCustomerChartOptions = this.barChartOptions;
    this.historiqueCustomerChartOptions['text'] = 'Historique Dossiers';

    this.chartHistoriqueEmployeeData.push({label: this.labelTimeBusy, data: [],type:'line'});
    this.chartHistoriqueEmployeeData.push({label: this.labelTimeFree, data: [], type:'line'});
    this.chartHistoriqueCustomerData.push({label: this.labelsHistoriqueTimeOccupation2, data: [], type:'line'});
    this.chartHistoriqueCustomerData.push({label: this.labelRevenus, data: [], type:'line'});
    this.dateRange = moment().toDate();
    this.rechercher();
  }

}
