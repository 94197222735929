import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { CommerceService } from '../commerce.service';

@Component({
  selector: 'app-form-min-commerces',
  templateUrl: './form-min-commerces.component.html',
  styleUrls: ['./form-min-commerces.component.scss']
})
export class FormMinCommercesComponent implements OnInit,OnChanges {
  searchText: string;
  model: NgModel;
  constructor(
    private alertService: AlertService,
    private formService: FormChangeService,
    private commerceService: CommerceService,
    private route: ActivatedRoute,
    public router: Router) {
  }
  ngOnChanges(changes: SimpleChanges): void {

    this.rechercher();
  }


  ngOnInit() {

    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadCommersRequest.subscribe(
      () => {
        this.rechercher();
      });
  }
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(commerces) {
    let filter = this.getTextFilter();
    let prospectFiltred = commerces;

    if (filter) {
      let text = filter.split(' ');
      prospectFiltred = commerces.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = (item['companyName'] && item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['tel1'] && item['tel1'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['adresse'] && item['adresse'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['cp'] && item['cp'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['tel1'] && item['tel1'].toUpperCase().indexOf(el.toUpperCase()) > -1);
            return tmp;
        });

        return flt;
      });

    }
    return prospectFiltred;
  }

  private getTextFilter() {
    return this.searchText;
  }
  rechercher() {
    this.getCommercesBackEnd();
  }

  onFormChange() {
    this.rechercher();
  }

  getCommercesBackEnd() {

    this.commerceService.getCommerces(null)
      .subscribe(
        (data) => {
          let commerces = data as Array<any>;
          commerces = this.findTextInProspect(commerces);

          this.formService.raiseCommersListEvent(commerces);
        }
        , (error) => {
          console.log(error);
          this.formService.raiseCommersListEvent([]);
        });
  }

}
