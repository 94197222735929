import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  getCoupons(couponId) {
    let url = environment.apiUrl + this.authenSer.getUserApiTargetUrl()+ '/coupons/';
    if(couponId){
      url += couponId;
    }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url,{withCredentials: true});
  }

deleteCoupon(couponId) {
    const url = environment.apiUrl + this.authenSer.getUserApiTargetUrl() + '/coupons/' + couponId;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(url);
  }

saveCoupon(coupon: {}) {
  let url = environment.apiUrl + this.authenSer.getUserApiTargetUrl() + '/coupons';
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  if (coupon['id']) {
    return this.http.put<any>(url, coupon, {headers , withCredentials: true});
  } else {
    return this.http.post<any>(url, coupon, {headers , withCredentials: true});
  }
}
constructor(private http: HttpClient,private authenSer: AuthenticationService) { }
}
