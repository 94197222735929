import { Component, OnInit, Input } from '@angular/core';
import { ParkingPlace } from '../class/parking-place';
import { TypePlace } from '../class/type-place.enum';

@Component({
  selector: 'app-parking-place',
  templateUrl: './parking-place.component.html',
  styleUrls: ['./parking-place.component.scss']
})

export class ParkingPlaceComponent implements OnInit {
  @Input()
  place: ParkingPlace;
  constructor() { }

  ngOnInit() {
  }
  get typeEnum() { return TypePlace; }

}
