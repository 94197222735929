import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  notifications(){
    //liste des notif
    let url= this.apiUrl+'admin/notificationConfigs';
    return this.http.get<any>(url,{withCredentials: true});
  }
  saveNotification(prospect) {
    const url = this.apiUrl  + 'admin/notificationConfigs';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http.post(url, prospect, { withCredentials: true, responseType:'text'});
  }

  putNotification(prospect) {
      const url = this.apiUrl  + 'admin/notificationConfigs';
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.put(url, prospect, { withCredentials: true });
  }
  createNotification(notification){
    let url = this.apiUrl + 'admin/notificationConfigs';

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //return this.http.post<any>(url,installation,{headers ,withCredentials: true});
    return this.http.post(url,notification,{headers , responseType: "text", withCredentials: true})
  }
  notificationsByInstallation(id){
    // notif d'install
    //FIXEME: Erreur 500 ?!

    let url =environment.apiUrl+'admin/notificationConfigs/digicode/'+id+'/notificationConfigs';
    return this.http.get<any>(url,{withCredentials: true});
  }
  deleteNotification(id){

    let url =environment.apiUrl+'admin/notificationConfigs/'+id;
    return this.http.delete<any>(url,{withCredentials: true});
  }
}
