import { Component, OnInit } from '@angular/core';
import { ParkingService } from '../services/parking.service';
import { FormChangeService } from '../services/form-change.service';
import { Router } from '@angular/router';
import { NgModel } from '@angular/forms';
import { AlertService } from '../services/alert-service.service';

@Component({
  selector: 'app-form-min-configurations',
  templateUrl: './form-min-configurations.component.html',
  styleUrls: ['./form-min-configurations.component.scss']
})
export class FormMinConfigurationsComponent implements OnInit {

  placeTypes = [
    {id: 1, name: 'PMR'},
    {id: 2, name: 'Paying'},
        {id: 3, name: 'Free'},
        {id: 4, name: 'Resident'},
        {id: 5, name: 'Private'},
        {id: 6, name: 'Rescue'},
        {id: 7, name: 'Delivering'}];

  selectedPlaceTypes;
  configurations = [];
  selectedCConfiguration;
  selectAll: boolean;
  searchText: string;
  numeroMin: number;
  numeroMax: number;
  model: NgModel;
  countAnnonces;
  page = 0;
  pageSize = 25;

  constructor(
    private alertService: AlertService,
    private referencielService: ParkingService,
    private listeConfigsService: FormChangeService,
    public router: Router) {

    }
  ngOnInit() {
    this.rechercher();
  }

  // efface le form de rechercher appelé depuis navBar
  clearForm(){
    this.rechercher();
  }
  creer(){
    this.router.navigate(['/configuration']);
  }
  rechercher() {
    let filter = this.getFilter();
    this.callBackend()
    .subscribe(
      data => {

        let tmp = data as Array<any>;
        let lstPlaces = data as Array<any>;
        if (tmp ) {
          if (filter) {
            if (filter['placeTypes'] != null  && filter['placeTypes'].length > 0) {
              tmp = tmp.filter(el => {
                return el['type'] != null && filter['placeTypes'].includes(el['type']);
              });
            }
            if (filter['searchText']){
               tmp =   this.findTextInPlaces(tmp, filter['searchText']);
            }
         }
        }
        this.listeConfigsService.raiseConfigurationListEvent(tmp);
      }
    , error => {
        let msg = error['error'] ? error['error']['error'] + ': ' : error;
        this.alertService.error( msg);
        this.listeConfigsService.raiseConfigurationListEvent({});

      });

  }
  callBackend() {
      return this.referencielService.getMyConfigurations(null);
  }

  private findTextInPlaces(places, text) {
    text = text.split(' ');
    return places.filter( function (item) {
      return text.every( function (el) {
        return item['label'].content.indexOf(el) > -1
          || item['street'].content.indexOf(el) > -1;
      });
    });
  }

  private getFilter() {
    let filter = {
      numeroMin: this.numeroMin ? this.numeroMin : null
      , numeroMax: this.numeroMax ? this.numeroMax : null
      , placeTypes: this.selectedPlaceTypes ? this.selectedPlaceTypes : null
      , searchText: this.searchText ? this.searchText : null
      , configurationsId: this.selectedCConfiguration ? this.selectedCConfiguration : null
    };
    return filter;
  }

}
