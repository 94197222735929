import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import { RangesFooter } from 'src/app/notifications/form-min-notifications/ranges-footer/ranges-footer.component';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { CouponService } from '../coupon.service';

@Component({
  selector: 'app-form-mincoupons',
  templateUrl: './form-mincoupons.component.html',
  styleUrls: ['./form-mincoupons.component.scss']
})
export class FormMincouponsComponent implements OnInit, OnChanges {
  rangesFooter = RangesFooter;
  dateRange: SatDatepickerRangeValue<Date>;
  @Input() installationId: any;
  searchText: string;
  model: NgModel;
  constructor(
    private alertService: AlertService,
    private formService: FormChangeService,
    private couponServ: CouponService,
    private route: ActivatedRoute,
    public router: Router) {
  }
  ngOnChanges(changes: SimpleChanges): void {

    this.rechercher();
  }


  ngOnInit() {

    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadCouponsRequest.subscribe(
      () => {
        this.rechercher();
      });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(prospects) {
    let filter = this.getTextFilter();
    let prospectFiltred = prospects;

    if (filter) {
      let text = filter.split(' ');
      prospectFiltred = prospects.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = (item['note'] && item['note'].toUpperCase().indexOf(el.toUpperCase()) > -1)

            return tmp;
        });

        return flt;
      });

    }
    prospectFiltred = this.filterPrspectDateRange(prospectFiltred);
    return prospectFiltred;
  }
  filterPrspectDateRange(prospectFiltred: Array<any>): any {
    let tmp = prospectFiltred;
    if(this.dateRange){
      let start = moment(this.dateRange.begin).startOf('day');
      let end = moment(this.dateRange.end).startOf('day');
      tmp = tmp.filter(element => {
        // let dateCreation = element.dateCreation?moment(element.dateCreation): null;
        let expirationDate = element.expirationDate?moment(element.expirationDate): null;
        return expirationDate && expirationDate.startOf('day').isBetween(start,end,undefined,'[]');
      });

    }
    return tmp;
  }

  private getTextFilter() {
    return this.searchText;
  }
  rechercher() {
    this.getCouponsBackEnd();
  }

  onFormChange() {
    this.rechercher();
  }

  getCouponsFromBackEnd() {
      return this.couponServ.getCoupons(null);
  }
  getCouponsBackEnd() {

    this.getCouponsFromBackEnd()
      .subscribe(
        (data) => {

          let tmpUsers = data as Array<any>;
           tmpUsers = this.findTextInProspect(tmpUsers);

          this.formService.raiseCouponsListEvent(tmpUsers);
        }
        , (error) => {

          console.log(error);
          this.formService.raiseCouponsListEvent([]);
        });
  }

}
