import { Component, OnInit, Input, Inject, LOCALE_ID, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ParkingPlace } from '../class/parking-place';
import { TimeFormatter } from '../class/time-formatter';
import * as moment from 'moment';
import { Observable, timer } from 'rxjs';
import { NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { ParkingService } from '../services/parking.service';
import { FormChangeService } from '../services/form-change.service';

@Component({
  selector: 'app-annonce',
  templateUrl: './annonce.component.html',
  styleUrls: ['./annonce.component.scss']
})
export class AnnonceComponent implements OnInit {
  subscription: any;

  constructor(private parrkingService: ParkingService,
              private listePlacesService: FormChangeService,
              public router: Router, private modalService: NgbModal) {}
  @Input() place: ParkingPlace;
  @Input()
  checkble: boolean;
  @Input()
  upperConfigId;
  countDown;
  countDownColor;
  carColor;
  everySecond: Observable<number> = timer(0, 1000);
  addedTimePicker: NgbTimeStruct;
  isPlaceCheck;
  ngOnInit() {
    this.subscription = this.everySecond.subscribe((seconds) => {
      let currentTime: moment.Moment = moment();
      this.getCountDown(currentTime);
      this.isPlaceCheck = this.upperConfigId == this.place['configurationId'];
  })
  }
  gotoMap(place){

    this.router.navigateByUrl('/map', { state: {place:place}});

  }
addTimeToPlace(){

  }
getCountDown(now) {
  let diff;
  if (this.place['busy']){
    let deadline = null;
    deadline =  moment(this.place['dateLimitBusy']);

    let before = moment(deadline).isBefore(now);
    if(before){//Red
       diff =moment(now).diff(deadline);
       let d = moment.duration(diff);
       this.countDown = (d.days() *24)+ d.hours()+'h '+d.minutes()+'m'+d.seconds();
       this.countDownColor = 'badge badge-pill badge-danger';
       this.carColor ="red";
    } else {//Green
       diff =moment(deadline).diff(now);
       let d = moment.duration(diff);
       this.countDown = (d.days() *24)+ d.hours()+'h '+d.minutes()+'m'+d.seconds();
       this.countDownColor = 'badge badge-pill badge-success';
       this.carColor ="green";
    }
  } else {
    let startFrom =  new Date(this.place['dateLastEmpty']).getTime();
    let now =moment.now();
    let after = moment(moment.now()).isSameOrAfter(startFrom);
    if(after){ //Green
       diff = moment(now).diff(startFrom);
       this.countDown = moment(diff).format('D[j] HH[h]mm:ss');
       this.countDownColor = 'badge badge-pill badge-success';
       this.carColor ="green";
    }
  }
}
  getLastBusyDate(){
    return TimeFormatter.format(this.place['dateLastBusy']);
  }
  getLastEmptyDate(){
    return TimeFormatter.format(this.place['dateLastEmpty']);
  }

  editPlace(id) {
    this.router.navigate(['/place', id]);
  }

  addTime(id) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.title = 'Ajouter du temps';
    modalRef.componentInstance.idPlace = id;
    let minutes = moment.duration();

    modalRef.result.then(result => {
        if (result && result > 0 ){

          this.parrkingService.addTimeToPlace(result, id).subscribe(
            data =>{
              console.log(data);
              this.listePlacesService.raisereloadSearchRequestEvent();
            },
            error => {
              console.log(error);
            }
          );
        }
      });
  }
  addremoveConfig(){
      if (this.isPlaceCheck) {
      this.parrkingService.linkPlace(this.upperConfigId, this.place.id).subscribe(
        data =>{
          this.place['configurationId'] = this.upperConfigId;
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.parrkingService.unlinkPlace(this.upperConfigId, this.place.id).subscribe(
        data =>{
          this.place['configurationId'] = null;
        },
        error => {
          console.log(error);
        }
      );
    }

  }
}
