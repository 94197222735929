import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ParkingService {
  createPlace(place: {}) {
    let url = environment.apiUrl+'places';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(url, place, {headers, withCredentials: true});
  }
  editPlace(place: {}) {
    let url = environment.apiUrl+ this.authenSer.getUserApiTargetUrl()+'places';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(url, place, {headers, withCredentials: true});
  }
  createConfiguration(config: {}) {
    let role = this.authenSer.getUserApiTargetUrl();
    let url = environment.apiUrl+ '/admin'+'/placeConfigurations';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(url, config, {headers, withCredentials: true});

  }
  editConfiguration(config: {}) {
    let role = this.authenSer.getUserApiTargetUrl();
    let url = environment.apiUrl+ '/admin'+'/placeConfigurations';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(url, config, {headers, withCredentials: true});
  }

  unlinkPlace(configurationId, placeId ) {
    let url = environment.apiUrl+ this.authenSer.getUserApiTargetUrl()+'/placeConfigurations/unlinkToPlace';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    url += '?configurationId='+configurationId + '&placeId='+placeId;
    return this.http.post<any>(url, null, {headers, withCredentials: true});

  }
  linkPlace(configurationId, placeId) {
    let url = environment.apiUrl+ this.authenSer.getUserApiTargetUrl()+'/placeConfigurations/linkToPlace';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // let body = new HttpParams();
    // body = body.set('configurationId', configurationId);
    // body = body.set('placeId', placeId);
    url += '?configurationId='+configurationId + '&placeId='+placeId;
    return this.http.post<any>(url, null, {headers, withCredentials: true});

  }
  addTimeToPlace(minutes, placeId) {
    let url = environment.apiUrl+ this.authenSer.getUserApiTargetUrl()+'/places/addtime';
    let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let body = new HttpParams();
    body = body.set('minutes', minutes);
    body = body.set('placeId', placeId);
    return this.http.post<any>(url, body.toString(), {headers, withCredentials: true});
  }
  // POST /places/addtime

  getMyListPlaceByFilter() {

      const url = environment.apiUrl+ this.authenSer.getUserApiTargetUrl()+'/places';
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.get(url,{withCredentials: true});
    }

    getMyListPlaceByType(filter: { numeroMin: number; numeroMax: number; placeTypes: any; searchText: string; configurationsId: any; }) {
      const url = environment.apiUrl+this.authenSer.getUserApiTargetUrl()+'/places/type/';
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.get( url + filter.placeTypes.join(', '), {withCredentials: true});
      }



  getMyConfigurations(id ) {
      const url = environment.apiUrl+this.authenSer.getUserApiTargetUrl()+'/placeConfigurations/';
      if(id){
        return this.http.get<any>(url + id, {withCredentials: true});
      }else {
        return this.http.get<any>(url, {withCredentials: true});
      }
  }
  saveMyConfigurations(configuration: {}) {
    let url = environment.apiUrl+this.authenSer.getUserApiTargetUrl()+'/placeConfigurations';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (configuration['id']) {
      return this.http.post<any>(url, configuration, {headers , withCredentials: true});
    } else {
      return this.http.put<any>(url, configuration, {headers , withCredentials: true});
    }
}
  constructor(private http: HttpClient,private authenSer: AuthenticationService) { }

  getMyPlacesById(id?) {
    let url = environment.apiUrl + this.authenSer.getUserApiTargetUrl()+'/places/';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (id) {
      return this.http.get<any>(url + id, {withCredentials: true});
    } else {
      return this.http.get<any>(url, {withCredentials: true});
    }
  }
  getMapElements() {
    let url = environment.apiUrl + this.authenSer.getUserApiTargetUrl()+'/maps/';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(url, {withCredentials: true});
  }
  postPlace() {
    const url = environment.apiUrl+this.authenSer.getUserApiTargetUrl()+'/places/execute';

  }
}
