import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { map } from 'rxjs/operators';
import { StoreSummaryService } from './store-summary.service';
import { ElementRef, ViewChild } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss']
})
export class DashComponent implements OnInit {
  type = 'line';
  data = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      borderColor: 'purple', //line color
      pointRadius:4,
      pointBorderColor: 'white',
      pointBackgroundColor:'#cd486b',
      label: "Occupation en %",
      fill:false,
      data: [65, 59, 80, 81, 56, 80, 90]
    }
  ]
};

options = {
  legend: {
    display: true
},
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{ display: true,
                gridLines: {
                    display:false
                }
            }],
    yAxes: [{
                display: true,
                gridLines: {
                    display:false
                }
            }]
}
}
type1 = 'doughnut';
data1 = {
labels: ["Centre", "Gare", "Mairie"],
datasets: [
  {
     data: [25,25,50],
     backgroundColor:['#ff0266','#81c784','#29b6f6']
  }
]
};

options1 = {
legend: {
  display: true
},
responsive: true,
maintainAspectRatio: false,
scales: {
  xAxes: [{ display: false,
              gridLines: {
                  display:false
              }
          }],
  yAxes: [{
              display: false,
              gridLines: {
                  display:false
              }
          }]
}
}
type2 = 'bar';
  data2 = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "",
      fill:'true',
      backgroundColor: '#2a5bc5',
      data: [65, 59, 80, 81, 56, 55, 90,65,100]
    }
  ]
};

options2 = {
  legend: {
    display: false
},
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{ display: false,
                gridLines: {
                    display:false
                }
            }],
    yAxes: [{
                display: false,
                gridLines: {
                    display:false
                }
            }]
}
}
type3 = 'bar';
  data3 = {


    labels: ['January', 'February', 'March', 'April'],
  datasets: [
    {
      label: "PMR",
      fill:'true',
      backgroundColor: '#ff0266',
      data:[10,11,12,13]
    },{
      label: "Zone blanche",
      fill:'true',
      backgroundColor: '#81c784',
      data:[20,21,22,23]
    },{
      label: "Zone bleu",
      fill:'true',
      backgroundColor: '#29b6f6',
      data:[31,32,33,34]
    }
  ]
};

options3 = {
  legend: {
    display: true
},
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{ display: true,
                gridLines: {
                    display:false
                }
            }],
    yAxes: [{
                display: true,
                gridLines: {
                    display:false
                }
            }]
}
}

type4 = 'pie';
  data4 = {


    labels: ['January', 'February', 'March', 'April'],
  datasets: [
    {
      fill:'true',
      backgroundColor: ['#ff0266','#81c784','#29b6f6','#8a3ab9'],
      data:[10,20,30,40]
    }
  ]
};

options4 = {
  legend: {
    display: true
},
  responsive: true,
  maintainAspectRatio: false,

}
//horizontalBar Char Data
horizontalBarData={
  labels: ["Africa", "Asia", "Europe", "Latin America", "North America"],
  datasets: [
    {
      label: "en min",
      backgroundColor: ["#8a3ab9", "#4c68d7","#cd486b","#fbad50","#bc2a8d"],
      data: [2478,5267,734,784,433]
    }
  ]
}
horizontalBarDataOptions={
  legend: { display: true },
  title: {
    display: true,
    text: 'sur 24h'
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{ display: true,
                gridLines: {
                    display:false
                }
            }],
    yAxes: [{
                display: true,
                gridLines: {
                    display:false
                }
            }]
}
}


bubbleData= {
  labels: "Africa",
  datasets: [
    {
      label: ["Centre"],
      backgroundColor: "#ff0266",
      borderColor: "rgba(255,221,50,1)",
      data: [{
        x: 21269017,
        y: 5.245,
        r: 15
      }]
    }, {
      label: ["Gare"],
      backgroundColor: "#ffde03",
      borderColor: "rgba(60,186,159,1)",
      data: [{
        x: 258702,
        y: 7.526,
        r: 10
      }]
    }, {
      label: ["Mairie"],
      backgroundColor: "#0336ff",
      borderColor: "#000",
      data: [{
        x: 3979083,
        y: 6.994,
        r: 15
      }]
    }, {
      label: ["Entr�e sud"],
      backgroundColor: "#8a3ab9",
      borderColor: "rgba(193,46,12,1)",
      data: [{
        x: 4931877,
        y: 5.921,
        r: 15
      }]
    }
  ]
}
bubbleOptions= {
  title: {
    display: true,
    text: 'sur 24h'
  }, scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: "Happiness"
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: "GDP (PPP)"
      }
    }]
  }
}

//stacked bar chart data and options

stackedBarData = {


  labels: ['PMR', 'Livraison', 'Zone bleu', 'Zone blanche'],
datasets: [
  {
    label: "Branch1",
    fill:'true',
    backgroundColor: '#ff0266',
    data:[10,5,15,30]
  },{
    label: "Branch2",
    fill:'true',
    backgroundColor: '#81c784',
    data:[20,21,22,23]
  },{
    label: "Branch3",
    fill:'true',
    backgroundColor: '#29b6f6',
    data:[31,32,33,34]
  }
]
};
stackedBaroptions = {
  legend: {
    display: true
},
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{ display: true,
              stacked:true,
                gridLines: {
                    display:false
                }
            }],
    yAxes: [{
                display: true,
                stacked:true,
                gridLines: {
                    display:false
                }
            }]
}
}


chartColors = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};

mutiLineChartData={

  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [{
    label: 'Gare',
    borderColor: this.chartColors.red,
    backgroundColor: this.chartColors.red,
    data: [10, 30, 46, 2, 8, 50, 0],
    fill: false,
  }, {
    label: 'Mairie',
    borderColor: this.chartColors.blue,
    backgroundColor: this.chartColors.blue,
    data: [7, 49, 46, 13, 25, 30, 22],
    fill: false,
  }]
}
position="bottom"
mutiLineChartDataoptions= {
  responsive: true,
  title: {
    display: true,
    text: 'Tooltip Position: ' + this.position
  }
}
  cardLayout =  new Object();
  miniCardData = [];
  selectedChart;
  @ViewChild('charts', { static: false }) public chartsRef: ElementRef;

  public salesChartLabels: Label[] = [];

  constructor(private breakpointObserver: BreakpointObserver,service : StoreSummaryService) {
    this.cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {
          return {
            columns: 1,
            miniCard: { cols: 1, rows: 1 },
            chart: { cols: 1, rows: 2 },
          };
        }
       return {
          columns: 4,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 2, rows: 2 },
        };
      })
    );
    service.getStoreSummary().subscribe(
      (data) =>{//cards data
        this.miniCardData = data;
      }
    )
  }
  focusChart(event){
    debugger;

    this.selectedChart = event.currentTarget.id;
    let charts = $(event.currentTarget).siblings('.chart');
  }
  ngOnInit() {
  }

}
