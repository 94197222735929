import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreditCardService } from 'src/app/services/credit-card.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare const Stripe: any;
@Component({
  selector: 'app-add-sepa-modal',
  templateUrl: './add-sepa-modal.component.html',
  styleUrls: ['./add-sepa-modal.component.scss']
})
export class AddSepaModalComponent implements OnInit {
  stripe;
  iban;
  loading = false;
  cardAdded = false;
  cardInfos: any;
  customer: any;
  myForm: FormGroup;
  constructor(private fb: FormBuilder, private creditCardServ: CreditCardService, private activeModal: NgbActiveModal) {

   }

   loadStripe() {

    if (!window.document.getElementById('stripe-custom-form-script')) {
      const s = window.document.createElement('script');
      s.id = 'stripe-custom-form-script';
      s.type = 'text/javascript';
      s.async = false;
      s.charset = 'utf-8';
      s.src = 'https://js.stripe.com/v3/';
      s.onload = this.initStripeElements();
      document.getElementsByTagName('head')[0].appendChild(s);
    }
  }
  initStripeElements(): (this: GlobalEventHandlers, ev: Event) => any {
    this.creditCardServ.getPublicKey()
      .subscribe(
        (data)=>{
          let publicKey = data['publicKey'];
          this.stripeElements(publicKey);
        },
        (error)=>{
        });
        return;
  }

  stripeElements(publicKey) {

    this.stripe = Stripe(publicKey);
    var elements = this.stripe.elements();

    // Element styles
    let style = {
      base: {
        fontSize: '16px',
        color: '#32325d',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: 'rgba(0,0,0,0.4)'
        }
      }
    };
    this.iban = elements.create('iban', {
      style: style,
      supportedCountries: ['SEPA'],
    });
    // Add an instance of the iban Element into the `iban-element` <div>.
    this.iban.mount('#iban-element');
    var errorMessage = document.getElementById('error-message');
    var bankName = document.getElementById('bank-name');

this.iban.on('change', function(event) {

  // Display bank name corresponding to IBAN, if available.
  if(bankName != null){
    if (event.bankName) {
      bankName.textContent = event.bankName;
      bankName.classList.add('visible');
    } else {
      bankName.classList.remove('visible');
    }
  }
});
    }


  changeLoadingState(isLoading) {
    document.querySelector('#spinner').classList.toggle('hidden');
    this.loading = isLoading;
  }
   showCardError(error) {
    //
    this.changeLoadingState(false);
    var errorMsg = document.querySelector('.sr-field-error');
    errorMsg.textContent = error.message;
    setTimeout(function() {
      errorMsg.textContent = '';
    }, 8000);
  }

  orderComplete(subscription) {
    this.changeLoadingState(false);
    this.cardAdded = true;
    this.cardInfos = subscription;
  }

  registerIban(paymentMethod, data) {

    this.creditCardServ.registerIban(paymentMethod,data).subscribe(
      (data)=>{
        this.orderComplete(data);
      },
      (error)=>{
        console.log(error);
        this.showCardError(error);

      }
    )
  }
  ProcessCreateCardResault(result){

    if (result.error) {
      this.showCardError(result.error);
    } else {
      let data = {
        name: this.myForm.get(["name"])!.value,
        iban: this.iban,
        mandateUrl: result.source.mandate.url,
      }

      this.registerIban(result.source.id, data);
    }
  }
  pay() {

    this.changeLoadingState(true);
    const elements = this.stripe.elements();
  var sourceData = {
    type: 'sepa_debit',
    currency: 'eur',
    owner: {
      name: this.myForm.get(["name"])!.value,
        email: this.customer.contactEmail,
        // address:{
        //   city: this.myForm.get(["city"])!.value,
        //   postal_code: this.myForm.get(["cp"])!.value,
        //   line1: this.myForm.get(["adress"])!.value,
        // }
    },
    mandate: {
      // Automatically send a mandate notification email to your customer
      // once the source is charged.
      notification_method: 'email',
    }
  };

  // Call `stripe.createSource` with the iban Element and additional options.
  this.stripe
  .createSource(this.iban, sourceData)
  .then(function(result) {

    if (result.error) {
    } else {
    }
    return result;
  })
  .then((result)=>this.ProcessCreateCardResault(result));
  ;
}

  close( data){
    this.activeModal.close(data);
  }
  ngOnInit() {
    this.initMyForm(),
    this.initStripeElements();
  }
  initMyForm() {
    this.myForm = this.fb.group({
      name: [this.customer?this.customer.companyName:"", Validators.required],
      //adress: [this.customer?this.customer.adress:"", Validators.required],
      //city: [this.customer?this.customer.city:"", Validators.required],
      //cp: [this.customer?this.customer.cp:"", Validators.required],
      //bic: ["", Validators.required]
    })
  }

}
