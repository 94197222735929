import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-offre',
  templateUrl: './offre.component.html',
  styleUrls: ['./offre.component.scss']
})
export class OffreComponent implements OnInit {
  @Input() commerce;
  offreCreateForm: FormGroup;
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder,
  private config: NgbTimepickerConfig
    ) {
      config.seconds = false;
      config.spinners = false;

  }

  validate() {
    this.activeModal.close(this.createFromForm());
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    let h = 0;
    let m = 0;
    if(this.commerce.timeParkFree){
      m = this.commerce.timeParkFree % 60;
      h = Math.floor(this.commerce.timeParkFree / 60);

    }
    this.offreCreateForm = this.fb.group({
      offer : new FormControl(this.commerce.offer),
      timeParkFree: new FormControl({hour: h, minute: m}, Validators.required),
    });
  }
  private createFromForm(): any {
    let tmp = {
      offer: this.offreCreateForm.get(["offer"]).value?this.offreCreateForm.get(["offer"]).value:null,
    };
    let tmsl = this.offreCreateForm.controls.timeParkFree;
    let hourStart =	tmsl!.value!.hour;
    let  minStart = tmsl!.value!.minute;
    tmp['creditTimeParam'] = hourStart* 60 + minStart;
    tmp['merchantId'] = this.commerce.id;
    // tmp['merchantId'] = this.authenSer.getCurrentUser()['id'];

    return tmp;
  }

}
