import { Component, OnInit, Input } from '@angular/core';
import { ParkingService } from '../services/parking.service';
import { FormArray, FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { TypePlace } from '../class/type-place.enum';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { Observable,} from 'rxjs';
import { map, tap, filter, scan, retry, catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { NgbDateStruct, NgbTimepickerConfig, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from '../services/configuration.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  // @Input()
  configId: any;
  config = {};
  model: NgModel;
  configurationForm: FormGroup;
    configurationTypes = [
      {id: 1, name: 'PMR', label: 'PMR'},
      {id: 2, name: 'Paying', label: 'Payante'},
      {id: 3, name: 'Free', label: 'Gratuite'},
      {id: 4, name: 'Resident',label: 'Résident'},
      {id: 5, name: 'Private', label: 'Privée'},
      {id: 6, name: 'Rescue', label: 'Pompier'},
      {id: 7, name: 'Delivering', label: 'Livraison'}
    ];
    jours = [
      {id: 0, name: 'Lundi'},
      {id: 1, name: 'Mardi'},
      {id: 2, name: 'Mercredi'},
      {id: 3, name: 'Jeudi'},
      {id: 4, name: 'Vendredi'},
      {id: 5, name: 'Samedi'},
      {id: 6, name: 'Dimanche'}
    ];
  startDayPicker: NgbDateStruct;
  startTimePicker: NgbTimeStruct;
  endDayPicker: NgbDateStruct;
  endTimePicker: NgbTimeStruct;

  private state$: Observable<object>;

  constructor(private parrkingService: ParkingService,
              private configurationServ: ConfigurationService,
              public router: Router, private fb: FormBuilder,
              private route: ActivatedRoute,
              private authenSrv: AuthenticationService,
              config: NgbTimepickerConfig) {
                config.spinners = false;
      this.route.params.subscribe( params => this.loadConfig(params) );

      this.configurationForm = this.fb.group({
        label: new FormControl('',[Validators.required]),
        tenantId: new FormControl(''),
        active : true,
        type: new FormControl('',[Validators.required]),
        unitTime: new FormControl(''),
  });
    }
    onSelectSemaine(index){
      const selected = this.jours.map(item => item.id);
      this.timeConfigs.at(index).get('dayInWeek').patchValue(selected);


    }
    onSelectWeekend(index){
      const selected = this.jours.filter(item => item.name == 'Samedi'|| item.name == 'Dimanche').map(item => item.id);
      this.timeConfigs.at(index).get('dayInWeek').patchValue(selected);

    }
  ngOnInit() {

  }
  loadConfig(data) {
    this.configId = data['id'];
    if (this.configId) {
      this.parrkingService.getMyConfigurations(this.configId).subscribe(
        data => {
          this.config = data;
          this.configurationForm.patchValue({
            label: data.label,
            tenantId: data.tenantId,
            type: data.type,
            active: data.active,
          });
          if (data.dayEnd) {
            let m = moment(data.dayEnd, "DD/MM/YYYY");
            this.endDayPicker = {year:m.year(), month: m.month(), day: m.day()};
          }
          if (data.dayStart) {
            let m = moment(data.dayStart, "DD/MM/YYYY");
            this.startDayPicker = {year:m.year(), month: m.month(), day: m.day()};
          }
          if (data.dayStart) {
            let m = moment(data.dayStart, "HH:mm");
            this.startTimePicker = {hour: m.hour(), minute: m.minute(), second: 0};
          }
        },
        error =>{

        }
      )
    }
  }
  getFormData(){
    let tmp = {};
    tmp = {
      label: this.configurationForm.get(['label'])!.value,
      tenantId: this.authenSrv.getCurrentUser().tenantId,
      active : this.configurationForm.get(['active'])!.value,
      type: this.configurationForm.get(['type'])!.value,
      unitTime: 'MINUTE',
    };

    let configPayingFormGroup =  this.configurationPayment;
    if (configPayingFormGroup) {
        let configPaying = {
          active: configPayingFormGroup.get(['active'])!.value,
          freeTimeMinute: '',
          price: configPayingFormGroup.get(['price'])!.value,
          untilPublicHoliday: configPayingFormGroup.get(['untilPublicHoliday'])!.value,
          timeConfig: [],
        }
        let freeTime = configPayingFormGroup.get(['freeTimeMinute'])!.value;
        let minutes = freeTime.minute + freeTime.hour * 60;
        configPaying.freeTimeMinute = minutes;
        let timeConfigsArray = configPayingFormGroup.get('timeConfig') as FormArray;
        if(timeConfigsArray) {
          timeConfigsArray.controls.forEach(element => {
            let timeConfig = {
              dayInWeek: element.get(['dayInWeek'])!.value,
              timeSlots:[],
            };

            let timeSlotsArray = element.get('timeSlots') as FormArray;
            timeSlotsArray.controls.forEach(element => {
              let tmsl = element.get(['TimeSlot']);
              let tmp = {
                hourStart:	tmsl.get(['TimeStart'])!.value!.hour,
                minStart: tmsl.get(['TimeStart'])!.value!.minute,
                hourEnd:	tmsl.get(['TimeEnd'])!.value!.hour,
                minEnd: tmsl.get(['TimeEnd'])!.value!.minute,
              };
              timeConfig.timeSlots.push(tmp);
            });
            configPaying.timeConfig.push(timeConfig);
          });

        }
        tmp['configPaying'] = configPaying;
    }
    return tmp;
  }
  saveConfiguration() {
    this.config = this.getFormData();

    if (this.configId) {
      this.parrkingService.editConfiguration(this.config).subscribe(
        data =>{
          this.router.navigate(['/configurations']);
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.parrkingService.createConfiguration(this.config).subscribe(
        data =>{
          this.router.navigate(['/configurations']);
        },
        error => {
          console.log(error);
        }
      );

    }
  }
  get configuration() {
    return this.configurationForm as FormGroup;
  }
  get configurationPayment() {
    return this.configurationForm.get('configPaying') as FormGroup;
  }
  get timeConfigs() {
    if(this.configurationForm.get('configPaying')){
      return this.configurationForm.get('configPaying').get('timeConfig') as FormArray;
    } else {
      return null;
    }

  }
  timeConfigsTimeSlots(index){
    return this.timeConfigs.controls[index].get('timeSlots') as FormArray;
  }
  removeConfigurationPayment(){
    this.configuration.removeControl('configPaying');
  }
  addConfigurationPayment(){
    this.configuration.addControl(
      "configPaying", this.fb.group({
        active: new FormControl(true),
        freeTimeMinute: new FormControl({hour: 0, minute: 0}),
        price: new FormControl('',Validators.required),
        untilPublicHoliday: new FormControl(false),
        timeConfig: this.fb.array([this.fb.group({
          dayInWeek: new FormControl('',Validators.required) ,
          timeSlots: this.fb.array([this.fb.group({
              TimeSlot: this.fb.group({
                TimeEnd: new FormControl({hour: 0, minute: 0}),
                TimeStart: new FormControl({hour: 0, minute: 0}),
              })
              })])
          })
        ])
      })
    );
  }
  isvalid(f){

    return f.form.valid;
  }
  removeTimeConfig(index){
    this.timeConfigs.removeAt(index);
  }

  addTimeConfig(){
    this.timeConfigs.push(
      this.fb.group({
        dayInWeek: new FormControl('') ,
        timeSlots: this.fb.array([this.fb.group({
            TimeSlot: this.fb.group({
              TimeEnd: new FormControl({hour: 0, minute: 0}),
              TimeStart: new FormControl({hour: 0, minute: 0}),
            })
            })])})
    );
  }
}
