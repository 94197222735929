import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Civilitee } from '../enum/units.enum';
import { ModalRASComponent } from '../modal-ras/modal-ras.component';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { FormChangeService } from '../services/form-change.service';
import { UserCreateComponent } from '../user/user-create/user-create.component';
import { UserUpdateComponent } from '../user/user-update/user-update.component';
import { UserService } from '../user/user.service';
import { DisplayConfigComponent } from './display-config/display-config.component';
import { DisplayCreateComponent } from './display-create/display-create.component';
import { DisplayMsgComponent } from './display-msg/display-msg.component';
import { DisplayUpdateComponent } from './display-update/display-update.component';
import { DisplayService } from './display.service';

@Component({
  selector: 'app-displays',
  templateUrl: './displays.component.html',
  styleUrls: ['./displays.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DisplaysComponent implements OnInit {
  searchText;
  lstDisplays = [];
  lstCusomers = [];
  displayedColumns = ['label','msg','etat','lastCom','status', 'automatic','Actions'
  ];
  civiliteeEnum = Civilitee ;
  expandedElement: {} | null;

  getKeys(object): string[] {
    return Object.keys(object);
  }

  onItemSelected(idx: number) {
    console.log(idx);
  }

  constructor(private formService: FormChangeService,
    private authenSer: AuthenticationService,
    private alertService: AlertService,
    private displayServ: DisplayService,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {
    this.subscribeformChange();
  }
  subscribeformChange() {
    this.formService.formDisplaySearchEvent.subscribe(
      (param) => {
        this.lstDisplays = param;
        this.lstDisplays.forEach( dis =>{
          dis['etat']= 'off';
          let lastCom = dis['lastCommunication'];
          if(lastCom){
            let duration = moment.duration(moment(new Date()).diff(lastCom));
            if(duration.asMinutes() < 2){
              dis['etat']= 'on';
            }
          }
        })
      });

  }

  create(): void {
    const modalRef = this.modalService.open(DisplayCreateComponent, { backdrop: 'static', keyboard: false });
  }
  editDisplay(display) {
    const modalRef = this.modalService.open(DisplayUpdateComponent, { backdrop: 'static', keyboard: false});
    modalRef.componentInstance.display = display;
  }
  config(display){
    const modalRef = this.modalService.open(DisplayConfigComponent, { backdrop: 'static', keyboard: false});
    modalRef.componentInstance.display = display;
  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static', keyboard: false });
    modalRef.componentInstance.title = "Suppresion d'un Display";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.deleteDisplay(id);
      }
    });
  }

  displayMsg(display){
    const modalRef = this.modalService.open(DisplayMsgComponent, { backdrop: 'static', keyboard: false});
    modalRef.componentInstance.display = display;
  }
  activateDisplay(el, event) {
    let enable = event.checked;
    let msg1, msg2;
    if(enable){
      msg1  = 'Paneau Activé';
      msg2 = 'Erreur lors de l\'activation ';
    } else {
      msg1  = 'Paneau Désactivé';
      msg2 = 'Erreur lors de la désactivation ';
    }
    this.displayServ.enableDisplay(enable, el.id).subscribe(
      () => {
        this.onDeleteSuccess(msg1);
      },
      () => this.onDeleteError(msg2)
    );
  }

  automaticDisplay(el, event) {
    let display = el ;
    display["automatic"] = event.checked;
    this.displayServ.putDisplays(display).subscribe(
      () => {
        this.onDeleteSuccess('Paneau en mode Automatique');
      },
      () => this.onDeleteError('Erreur lors de la mise en mode automatique')
    );
  }

  deleteDisplay(id) {
    this.displayServ.deleteDisplays(id).subscribe(
      () => {
        this.onDeleteSuccess('Suppression effectuée.');
      },
      () => this.onDeleteError('Erreur de suppression.')
    );

  }

  protected onDeleteSuccess(msg): void {
    this.alertService.success(msg, false);
    this.formService.raisereloadSearchRequestDisplayEvent();
  }


  protected onDeleteError(msg): void {
    this.alertService.warn(msg, false);
  }

}
