import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import { RangesFooter } from 'src/app/notifications/form-min-notifications/ranges-footer/ranges-footer.component';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ParkingService } from 'src/app/services/parking.service';
import { StatsService } from 'src/app/stats/stats.service';
import { ZonesService } from 'src/app/zones/zones.service';

@Component({
  selector: 'app-form-min-dash',
  templateUrl: './form-min-dash.component.html',
  styleUrls: ['./form-min-dash.component.scss']
})
export class FormMinDashComponent implements OnInit {
  rangesFooter = RangesFooter;
  dateRange: SatDatepickerRangeValue<Date>;
  @Input() installationId: any;
  searchText: string;
  model: NgModel;
  selectedTargetType = 'zone';
  targets;
  selectedTarget;
  units = [{id: 1, code:'HOUR', label: 'Heures'},
  {id: 2, code:'MINUTE', label: 'Minutes'}];
  selectedUnite = 'HOUR';
  targetTypes = [
    {id: 1, code:'zone', label: 'Zones'},
    {id: 2, code:'place', label: 'Places'}];
  constructor(
    private zoneServ: ZonesService,
    private placeServ: ParkingService,
    private formService: FormChangeService,
    private statsServ: StatsService,
    private route: ActivatedRoute,
    public router: Router) {
  }

  ngOnInit() {
    let start = moment().startOf('month');
    let end = moment().endOf('month');
    this.dateRange = {begin: start.toDate() , end: end.toDate()};
    this.perimetreChange();
    this.rechercher();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadNotificationsRequest.subscribe(
      () => {
        this.rechercher();
      });
  }
  perimetreChange() {
    this.getTargets()
  }

  rechercher() {
    let data = {};
    data['target'] = this.selectedTargetType;
    data['targetIds'] = this.selectedTarget;
    data['timeFrom'] = moment(this.dateRange.begin).startOf('day').format('YYYY-MM-DD');
    data['timeTo'] = moment(this.dateRange.end).startOf('day').format('YYYY-MM-DD');
    data['unitTime'] = 'HOUR';
    this.statsServ.getAdminStats(data).subscribe(
      (data) => {
        debugger;
      },
      (error) =>{
        debugger;
      }
    );
  }

  // onFormChange() {
  //   this.rechercher();
  // }

  getTargets() {
    if(this.selectedTargetType == "zone"){
      this.zoneServ.getZones().subscribe(
        (data) =>{
          this.targets = data;
        }
      )
    }
    if(this.selectedTargetType == "place"){
      this.placeServ.getMyPlacesById().subscribe(
        (data) =>{
          let places = data;
          places.forEach(el => {
            el.label = el.street + ', Place N° : '+ el.label;
          });
          this.targets = places;
        }
      )
    }
  }

}
