import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from '@angular/core';
import { ModalRASComponent } from 'src/app/modal-ras/modal-ras.component';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ParkingService } from 'src/app/services/parking.service';
import { ZonesService } from 'src/app/zones/zones.service';

@Component({
  selector: 'app-config-place',
  templateUrl: './config-place.component.html',
  styleUrls: ['./config-place.component.scss']
})
export class ConfigPlaceComponent implements OnInit {
  subscription: any;

  constructor(private parrkingService: ParkingService,
              private formService: FormChangeService,
              private zoneServ: ZonesService,
              public router: Router, private modalService: NgbModal) {}
  @Input() place;
  @Output("deleteEvent") deleteEvent = new EventEmitter();
  ngOnInit() {
  }
  delete(place) {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static', keyboard: false });
    modalRef.componentInstance.title = "Sortir une place de la zone";
    modalRef.componentInstance.message = "Confirmer la modification ?";
    modalRef.componentInstance.withCancel = true;
    debugger;
    modalRef.result.then(result => {
      if (result == true) {
        this.deleteEvent.emit(place);
      }
    });
  }

}

