import { Component, OnInit, Inject, LOCALE_ID, Output, EventEmitter, Input } from '@angular/core';
import { NgModel } from '@angular/forms';
import { stringify } from 'querystring';
import { Router, NavigationEnd } from '@angular/router';
import { FormChangeService } from '../services/form-change.service';
import { environment } from '../../environments/environment';
import { ParkingService } from '../services/parking.service';
import { AlertService } from '../services/alert-service.service';
import { ZonesService } from '../zones/zones.service';

@Component({
  selector: 'app-form-min-annoce',
  templateUrl: './form-min-annoce.component.html',
  styleUrls: ['./form-min-annoce.component.scss']
})
export class FormMinAnnoceComponent implements OnInit {
@Input()
upperConfigId;
  placeTypes = [
    {id: 1, name: 'PMR'},
    {id: 2, name: 'Paying'},
        {id: 3, name: 'Free'},
        {id: 4, name: 'Resident'},
        {id: 5, name: 'Private'},
        {id: 6, name: 'Rescue'},
        {id: 7, name: 'Delivering'}];

  selectedPlaceTypes;
  selectedZone = [];
  configurations = [];
  zones = [];
  selectedCConfiguration;
  selectAll: boolean;
  searchText: string;
  numeroMin: number;
  numeroMax: number;
  model: NgModel;
  countAnnonces;
  page = 0;
  pageSize = 25;

  constructor(
    private alertService: AlertService,
    private referencielService: ParkingService,
    private listePlacesService: FormChangeService,
    public router: Router,
    private zoneServ: ZonesService) {

    }
  ngOnInit() {
    this.rechercher();
    this.subscribeReloadSearchRequest();
  }
  subscribeReloadSearchRequest() {
    this.listePlacesService.reloadSearchRequest.subscribe(
        (param) => {
          this.rechercher();
   });
  }
  // efface le form de rechercher appelé depuis navBar
  clearForm(){
    this.rechercher();
  }
  creer(){
    if (this.upperConfigId){
      this.router.navigate(['confiuration/'+this.upperConfigId+'/place']);
    } else {
      this.router.navigate(['/place']);
    }
  }
  rechercher() {
    let filter = this.getFilter();
    this.callBackend(filter)
    .subscribe(
      data => {
        this.zoneServ.getZones().subscribe(
          (res) =>{
            this.zones = res as Array<any>;

          },
          (error) => {

          }
        );
        let tmp = data as Array<any>;

        if (tmp ) {
          if (filter) {
            if (filter['placeTypes'] != null  && filter['placeTypes'].length > 0) {
              tmp = tmp.filter(el => {
                return el['type'] != null && filter['placeTypes'].includes(el['type']);
              });
            }
            if (filter['searchText']){
               tmp =   this.findTextInPlaces(tmp, filter['searchText']);
            }
         }

         if(this.selectedZone && this.selectedZone.length > 0){
            tmp = tmp.filter(el => {
              return el['zoneId'] != null && this.selectedZone.includes(el['zoneId']);
            });
         }
        }
        this.listePlacesService.raisePlaceListEvent(tmp);
      }
    , error => {

        let msg = error['error'] ? error['error']['error'] + ': ' : error;
        this.alertService.error(msg);
        this.listePlacesService.raisePlaceListEvent({});

      });

  }
  callBackend(filter) {
    return this.referencielService.getMyListPlaceByFilter();
  }

  private findTextInPlaces(places, text) {
    text = text.split(' ');
    return places.filter(function(item) {
      return text.every(function(el) {
        return item['label'].indexOf(el) > -1
          || item['street'].indexOf(el) > -1;
      });
    });
  }

  private getFilter() {
    let filter = {
      numeroMin: this.numeroMin ? this.numeroMin : null
      , numeroMax: this.numeroMax ? this.numeroMax : null
      , placeTypes: this.selectedPlaceTypes ? this.selectedPlaceTypes : null
      , searchText: this.searchText ? this.searchText : null
      , configurationsId: this.selectedCConfiguration ? this.selectedCConfiguration : null
    };
    return filter;
  }

}
