import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CommerceService {

  getCommerces(commerceId) {
      let url = environment.apiUrl + this.authenSer.getUserApiTargetUrl()+ '/merchants/';
      if(commerceId){
        url += commerceId;
      }
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.get(url,{withCredentials: true});
    }

  deleteCommerce(commerceId) {
      const url = environment.apiUrl + this.authenSer.getUserApiTargetUrl() + '/merchants/' + commerceId;
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.delete(url);
    }

  saveCommerce(commerce: {}) {
    let url = environment.apiUrl+this.authenSer.getUserApiTargetUrl() + '/merchants';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    if (commerce['id']) {
      return this.http.put<any>(url, commerce, {headers , withCredentials: true});
    } else {
      return this.http.post<any>(url, commerce, {headers , withCredentials: true});
    }
  }
  saveParam(param: any) {
    let url = environment.apiUrl + this.authenSer.getUserApiTargetUrl() + '/merchants/param';
    url += '?creditTimeParam='+ param.creditTimeParam +'&offer='+ param.offer + '&merchantId='+ param.merchantId;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post<any>(url, null, {headers , withCredentials: true});
  }
  constructor(private http: HttpClient,private authenSer: AuthenticationService) { }

}
