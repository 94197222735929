import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable, timer } from 'rxjs';
import { ParkingPlace } from 'src/app/class/parking-place';
import { TimeFormatter } from 'src/app/class/time-formatter';
import { ModalRASComponent } from 'src/app/modal-ras/modal-ras.component';
import { ModalComponent } from 'src/app/modal/modal.component';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ParkingService } from 'src/app/services/parking.service';
import { ZonesService } from '../../zones.service';

@Component({
  selector: 'app-zone-place',
  templateUrl: './zone-place.component.html',
  styleUrls: ['./zone-place.component.scss']
})
export class ZonePlaceComponent implements OnInit {
  subscription: any;

  constructor(private parrkingService: ParkingService,
              private formService: FormChangeService,
              private zoneServ: ZonesService,
              public router: Router, private modalService: NgbModal) {}
  @Input() place;
  ngOnInit() {
  }
  delete(place) {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static', keyboard: false });
    modalRef.componentInstance.title = "Sortir une place de la zone";
    modalRef.componentInstance.message = "Confirmer la modification ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        place['zoneId'] = null;
        this.parrkingService.editPlace(place).subscribe(
          (res) => {
            this.formService.raisereloadSearchRequestZoneEvent();
          }
        )
      }
    });
  }

}
