import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Civilitee } from '../enum/units.enum';
import { ModalRASComponent } from '../modal-ras/modal-ras.component';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { FormChangeService } from '../services/form-change.service';
import { UserCreateComponent } from '../user/user-create/user-create.component';
import { UserUpdateComponent } from '../user/user-update/user-update.component';
import { UserService } from '../user/user.service';
import { EquipeService } from './equipe.service';

@Component({
  selector: 'app-equipe',
  templateUrl: './equipe.component.html',
  styleUrls: ['./equipe.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EquipeComponent implements OnInit {
    searchText;
    lstUsers = [];
    lstCusomers = [];
    displayedColumns = ['expandIcon','Nom/Prenom','Identifiant','Email','lastConnection','Actions'
    ];
    civiliteeEnum = Civilitee ;
    expandedElement: {} | null;

    getKeys(object): string[] {
      return Object.keys(object);
    }

    onItemSelected(idx: number) {
      console.log(idx);
    }

    constructor(private formService: FormChangeService,
      private authenSer: AuthenticationService,
      private alertService: AlertService,
      private userService: UserService,
      private modalService: NgbModal,
      private equiteSrv: EquipeService,
    ) { }

    ngOnInit() {
      this.subscribeformChange();
    }
    subscribeformChange() {
      this.formService.formEquipeSearchEvent.subscribe(
        (param) => {

          this.lstUsers = param;

        });

    }

    create(): void {
      const modalRef = this.modalService.open(UserCreateComponent, { backdrop: 'static' });
    }
    editUser(user) {
      const modalRef = this.modalService.open(UserUpdateComponent, { backdrop: 'static' });
      modalRef.componentInstance.user = user;

    }
    requestLocation(user){

      const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static', keyboard: false });
      modalRef.componentInstance.title = "Demande de localisation";
      modalRef.componentInstance.message = "demander la localisation de " + user.firstname +' '+user.name;
      modalRef.componentInstance.withCancel = true;
      modalRef.result.then(result => {
        if (result == true) {
          this.equiteSrv.requestPosition(user.id).subscribe(
            (result) =>{
              this.alertService.success('Demande de localisation envoyée.', true);
              this.formService.raisereloadSearchRequestEquipeEvent();
            },
            (error) =>{

            }
          );


        }
      });
    }
    showLocation(user){

    }
    openDeleteConfirm(id): void {
      const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static', keyboard: false });
      modalRef.componentInstance.title = "Suppresion d'un utilisateur";
      modalRef.componentInstance.message = "Confirmer la suppression ?";
      modalRef.componentInstance.withCancel = true;
      modalRef.result.then(result => {
        if (result == true) {
          this.deleteUser(id);
        }
      });
    }

    deleteUser(userId) {
      this.userService.deleteUser(userId).subscribe(
        () => {
          this.onDeleteSuccess();
        },
        () => this.onDeleteError()
      );

    }
    protected onDeleteSuccess(): void {
      this.alertService.success('Suppression effectuée.', false);
      this.formService.raisereloadSearchRequestEquipeEvent();
    }


    protected onDeleteError(): void {
      this.alertService.warn('Erreur de suppression.', false);
    }

}
