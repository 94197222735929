import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRASComponent } from '../modal-ras/modal-ras.component';
import { AlertService } from '../services/alert-service.service';
import { FormChangeService } from '../services/form-change.service';
import { CommerceService } from './commerce.service';
import {
  trigger,
  transition,
  state,
  style,
  animate,
} from "@angular/animations";
import { ModalComponent } from '../modal/modal.component';
import { OffreComponent } from './offre/offre.component';
import * as moment from 'moment';
@Component({
  selector: 'app-commerces',
  templateUrl: './commerces.component.html',
  styleUrls: ['./commerces.component.scss'],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0px" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class CommercesComponent implements OnInit {
  commerces;
  constructor(private formService: FormChangeService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private commerceServ: CommerceService,
    private router: Router,
    private route: ActivatedRoute) { }

@ViewChild(MatSort, {static: true}) sort: MatSort;
    displayedColumns = [
    'companyName','city','cp','tel1','creditTime',
    'actions',
    ];
    expandedElement: {} | null;
    sortData(sort: Sort) {

      const data = this.commerces;
      if (!sort.active || sort.direction === '') {
        return;
      }
      if(this.commerces){
        this.commerces.data = data.data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
            case 'companyName': return this.compare(a.companyName, b.companyName, isAsc);
            case 'city': return this.compare(a.city, b.city, isAsc);
            case 'adresse': return this.compare(a.adresse, b.adresse, isAsc);
            case 'cp': return this.compare(a.cp, b.cp, isAsc);
            case 'tel1': return this.compare(a.tel1, b.tel1, isAsc);
            default: return 0;
          }
        });
    }
  }

   compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
    ngAfterViewInit() {
      if(this.commerces){
        this.commerces.sort = this.sort;
      }
    }
    ngOnInit() {
      this.subscribeformChange();

    }
    subscribeformChange() {
      this.formService.formCommersSearchChanged.subscribe(
        (param) => {
          let data = param;
          data.forEach(element => {
            let tmp =  moment.duration(2000, "minutes");
            let t = tmp.days() > 0 ?tmp.days() + 'J ': '';
            t += tmp.hours() + 'H ';
            t += tmp.minutes();
            element['creditBalanceFormated'] = t;
          });
          this.commerces = new MatTableDataSource(data);
            this.commerces.sort = this.sort;
        });

    }
    openDeleteConfirm(id): void {
      const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
      modalRef.componentInstance.title = "Suppresion d'un Commerce";
      modalRef.componentInstance.message = "Confirmer la suppression ?";
      modalRef.componentInstance.withCancel = true;
      modalRef.result.then(result => {
        if (result == true) {
          this.delete(id);
        }
      });
    }
    create(){
      this.router.navigateByUrl('/create-commerce');
    }
    delete(id) {
      this.commerceServ.deleteCommerce(id).subscribe(
        () => this.onDeleteSuccess(),
        () => this.onDeleteError()
      );

    }
    offer(commerce){
      const modalRef = this.modalService.open(OffreComponent, { backdrop: 'static' ,keyboard: false});
      modalRef.componentInstance.commerce = commerce;
      modalRef.result.then(data => {
        if (data) {
          this.commerceServ.saveParam(data).subscribe(
            data => {
              this.formService.raiseReloadCommersSearchRequestEvent();
            },
            error => {
              const msg = "erreur lors de l'aquitement du message";
              this.alertService.error(msg);
            }
          );
        }
      });
    }
    edit(commerce){

      this.router.navigateByUrl('/update-commerce', { state: {commerce:commerce}});
    }
    protected onDeleteSuccess(): void {
      this.alertService.success('Suppression effectuée.', false);
      this.formService.raiseReloadCommersSearchRequestEvent();
    }


    protected onDeleteError(): void {
      this.alertService.warn('Erreur de suppression.', false);
    }
}
