import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  save(configuration: {}) {
    let url = environment.apiUrl+'admin'+'/placeConfigurations';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (configuration['id']) {
      return this.http.post<any>(url, configuration, {headers , withCredentials: true});
    } else {
      return this.http.put<any>(url, configuration, {headers , withCredentials: true});
    }
}
  constructor(private http: HttpClient,private authenSer: AuthenticationService) { }
}
