import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  getCustomerCard() {
    const url = apiUrl + 'admin/payments/card';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
  }
  getCusstomerPaymentMethods(){
    let subUrl = this.authenSer.getUserApiTargetUrl();
    const url = apiUrl + subUrl + '/payments/paymentMethods';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
  }
  getCustomerIBAN() {
    let subUrl = this.authenSer.getUserApiTargetUrl();
    const url = apiUrl + subUrl +'/payments/iban';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
  }
  constructor(private http: HttpClient, private authenSer: AuthenticationService) { }

  getPublicKey() {
    let subUrl = this.authenSer.getUserApiTargetUrl();
    const url = apiUrl + subUrl +'/payments/publicKeyJson';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
  }
  registerCard(paymentMethod){
    const formData: FormData = new FormData();
    formData.append('paymentMethodId', paymentMethod);
    let subUrl = this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/payments/registerCard';
    let headers = new HttpHeaders();
    return this.http.post(url, formData, { headers, withCredentials: true });
  }
  registerIban(id,data){
    const formData: FormData = new FormData();
    formData.append('sourceId', id);
    formData.append('name', data.name);
    formData.append('mandateUrl', data.mandateUrl);
    formData.append('cp', data.cp);
    formData.append('city', data.city);
    formData.append('adress', data.adress);
    let subUrl = this.authenSer.getUserApiTargetUrl();
    let url = apiUrl + subUrl + '/payments/registerIban';
    let headers = new HttpHeaders();
    return this.http.post(url, formData, { headers, withCredentials: true });
  }
}
