import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Role } from 'src/app/class/role';
import { CustomerService } from 'src/app/customer/customer.service';
import { DisplayService } from 'src/app/displays/display.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ParkingService } from 'src/app/services/parking.service';
import { TenantService } from 'src/app/services/tenant.service';
import { ZonesService } from '../zones.service';

@Component({
  selector: 'app-zone-create',
  templateUrl: './zone-create.component.html',
  styleUrls: ['./zone-create.component.scss']
})
export class ZoneCreateComponent implements OnInit {
  userCreateForm: FormGroup;
  tenantId;
  selectedCustomer;
  roles = new Array();
  selectedRoles = new Array();
  selectedTenant: any[];
  tenants: Object;
  lstplace: Array<any>;
  selectedPlaces: Array<any>;
  @ViewChild('addresstext', { static: false }) addresstext: any;

  constructor(protected customerService: CustomerService,
    protected zoneServ: ZonesService,
    private fb: FormBuilder,
    private tenantServ: TenantService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    private parkingServ: ParkingService,
    public activeModal: NgbActiveModal) {


     }

  ngOnInit(): void {
    this.initForm();
  }
  initForm() {
    this.userCreateForm = this.fb.group({
      label: [],
      tenantId: [],

    });
    this.rechercher();
  }
  get f() { return this.userCreateForm.controls; }



  private createFromForm() {
    let tmp = {
      label: this.userCreateForm.get(['label']).value,
      active: this.userCreateForm.get(['active']).value,

    };
    if (!this.authenSer.isAdmin) {
      tmp['tenantId'] = this.userCreateForm.get(["tenantId"])!.value;
    } else {
      tmp['tenantId'] = this.authenSer.getCurrentUser()['tenantId'];
    }
    return tmp;

  }

  onSubmit(): void {
    if (this.userCreateForm.invalid) {
      return;
    } else {
      let userForm = this.createFromForm();

      this.subscribeToSaveResponse(this.zoneServ.saveZones(userForm, this.selectedPlaces));
    }
  }
  valid(f){
    return f.valid;
  }
  rechercher() {
    this.parkingServ.getMyPlacesById().subscribe(
      (places)=>{
        if(places && places.length > 0){
          this.lstplace = places;
          this.lstplace.forEach(el =>{
            el.label = el.street + ', Place N° : '+ el.label;
          });
        }
        if(this.authenSer.isSupperAdmin){
          this.getTenantsFromBackEnd()
          .subscribe(
            data => {
              let tmp = data as Array<any>;
              this.tenants = data;
            }
            , error => {
              console.log(error);
            });
        }
      }
    )


  }

  getCustomersFromBackEnd(filter) {
    return this.customerService.getCustomers();
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Utilisateur sauvegardé !', true);
    this.formService.raisereloadSearchRequestZoneEvent();
    this.close();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', true);
  }
}
